import { rewardsUrls } from "@common/api/rewardsUrls";
import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import {
  RewardPointsRedemptionResponseType,
  RewardRedemptionHistoryType,
  RewardsType,
} from "@common/types/rewardsTypes";

export const rewardsApi = {
  affiliates: {
    retrieve: (affiliateId: IdType): Promise<RewardsType> =>
      ajax.get(rewardsUrls.affiliates.retrieve(affiliateId)),
    redeemRewardedPoints: (
      affiliateId: IdType,
      amount: number
    ): Promise<RewardPointsRedemptionResponseType> => {
      const body = { amount };

      return ajax.post(
        rewardsUrls.affiliates.redeemRewardedPoints(affiliateId),
        body
      );
    },
    rewardRedemptionHistory: (
      affiliateId: IdType
    ): Promise<RewardRedemptionHistoryType> =>
      ajax.get(rewardsUrls.affiliates.rewardRedemptionHistory(affiliateId)),
  },
  customers: {
    retrieve: (customerId: IdType): Promise<RewardsType> =>
      ajax.get(rewardsUrls.customers.retrieve(customerId)),
    addPoints: (
      customerId: IdType,
      points: number
    ): Promise<{ success: boolean }> =>
      ajax.post(rewardsUrls.customers.addPoints(customerId), { points }),
  },
};
