import {
  ChangePasswordResponseType,
  GetKwhUsageResponseType,
} from "@common/api/customerResponseTypes";
import {
  changePassword,
  kwhUsage,
  setAveragedBillingMonthlyCharge,
} from "@common/api/customerUrls";
import { generateGetHook } from "@common/api/generateGetHook";
import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";

export const changePasswordApi: (
  id: IdType,
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
) => Promise<ChangePasswordResponseType> = (
  id,
  oldPassword,
  newPassword,
  confirmNewPassword
) => {
  const body = { oldPassword, newPassword, confirmNewPassword };

  return ajax.post(changePassword(id), body);
};

export const useGetKwhUsage = generateGetHook<GetKwhUsageResponseType, IdType>(
  kwhUsage
);

export const setAveragedBillingMonthlyChargeApi: (
  id: IdType,
  averagedBillingMonthlyCharge: number
) => Promise<undefined> = (id, averagedBillingMonthlyCharge) => {
  const body = { averagedBillingMonthlyCharge };

  return ajax.put(setAveragedBillingMonthlyCharge(id), body);
};
