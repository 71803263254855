import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

export const useFieldErrorStyles = makeStyles<typeof rhythmTheme, "root">(
  (theme) =>
    createStyles({
      root: {
        color: theme.palette.error.main,
        marginTop: "4px",
      },
    })
);

export const FieldError: FC = ({ children }) => {
  const classes = useFieldErrorStyles();

  return <div className={classes.root}>{children}</div>;
};
