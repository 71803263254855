import { api } from "@common/api/api";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { ValidInboundEDIType } from "@common/types/ediTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Grid, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useGenerateInboundEDIFormStyles } from "@ops/components/GenerateInboundEDIForm/GenerateInboundEDIForm.style";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { FC } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

interface GenerateInboundEDIFormValues {
  ediIntent: string;
}
interface GenerateInboundEDIFormProps {
  validInboundEDI: ValidInboundEDIType[];
}

export const GenerateInboundEDIForm: FC<GenerateInboundEDIFormProps> = ({
  validInboundEDI,
}) => {
  const customerState = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const classes = useGenerateInboundEDIFormStyles();

  const onSubmit = async (values: GenerateInboundEDIFormValues) => {
    const [triggerError] = await handleAjaxCall(
      api.ediProvider.triggerEDIGeneration(customer.id, values.ediIntent)
    );

    if (triggerError) {
      flash.error("Unable to generate EDI Message. Please try again.");
      return;
    }

    const [updatedCustomerError, updatedCustomer] = await handleAjaxCall(
      api.customers.retrieve(customer.id)
    );

    if (updatedCustomerError) {
      flash.error("Unable to get new customer's info. Please refresh website.");
      return;
    }

    dispatch(customerUpdated(updatedCustomer as CustomerType));
    flash.success("EDI message created");
  };

  return (
    <Form<GenerateInboundEDIFormValues>
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item>
              <FormControl className={classes.select}>
                <Field name="ediIntent">
                  {(props) => (
                    <Select
                      {...props.input}
                      data-testid="generateInboundEDI__select"
                      displayEmpty
                      renderValue={(value) => {
                        const label = validInboundEDI.find(
                          (edi: ValidInboundEDIType) => value === edi.intent
                        )?.label;

                        return label || "Select EDI Type";
                      }}
                    >
                      {validInboundEDI.map((edi) => (
                        <MenuItem value={edi.intent} key={edi.intent}>
                          {edi.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
              </FormControl>
            </Grid>
            <Grid item>
              <RhOutlineButton
                type="submit"
                color="secondary"
                className="generateInboundEDI__submit"
                data-testid="generateInboundEDI__submit"
              >
                Send
              </RhOutlineButton>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
