import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { SignInPage } from "@ops/pages/SignInPage/SignInPage";
import { UnauthorizedPage } from "@ops/pages/UnauthorizedPage/UnauthorizedPage";
import {
  customersPath,
  homePath,
  loginCallbackPath,
  signInPath,
  unauthorizedPath,
} from "@ops/routes/routePaths";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const LoggedOutRoutes: FC = () => {
  const {
    authState: { isAuthenticated, isPending },
  } = useOktaAuth();

  return (
    <Switch>
      <Route path={signInPath()} component={SignInPage} />
      <Route path={unauthorizedPath()} component={UnauthorizedPage} />
      <Route exact path={loginCallbackPath()} component={LoginCallback} />
      <Route
        exact
        path={homePath()}
        render={() => {
          return isPending ? (
            <RhCircularProgress />
          ) : (
            <Redirect
              from={homePath()}
              to={isAuthenticated ? customersPath() : signInPath()}
            />
          );
        }}
      />
    </Switch>
  );
};
