import { EnvTypes } from "@common/enums/envTypes.enum";
import { Customer } from "@common/models/Customer.model";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import { CustomerType } from "@common/types/customerTypes";
import { ValidInboundEDIType } from "@common/types/ediTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box } from "@material-ui/core";
import { ChangeServiceEndDateForm } from "@ops/components/ChangeServiceEndDateForm/ChangeServiceEndDateForm";
import { ChangeServiceStartDateForm } from "@ops/components/ChangeServiceStartDateForm/ChangeServiceStartDateForm";
import { EDIMessages } from "@ops/components/EDIMessages/EDIMessages";
import { GenerateInboundEDIForm } from "@ops/components/GenerateInboundEDIForm/GenerateInboundEDIForm";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";

interface AccountEDIProps {
  validInboundEDI: ValidInboundEDIType[] | null;
}

export const AccountEDI: FC<AccountEDIProps> = ({ validInboundEDI }) => {
  const customerState = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);
  const inProduction = getEnv() === EnvTypes.production;

  return (
    <Box marginBottom={2} id="edi">
      <Box marginBottom={1}>
        <RhTypography variant="h2">EDI</RhTypography>
      </Box>
      <Box marginBottom={3}>
        <Box marginBottom={1}>
          <RhTypography variant="h4">Update Service Start Date</RhTypography>
        </Box>
        <ChangeServiceStartDateForm />
      </Box>
      <Box marginBottom={3}>
        <Box marginBottom={1}>
          <RhTypography variant="h4">Cancel Service</RhTypography>
        </Box>
        <ChangeServiceEndDateForm disabled={customer.canCancelDisabled()} />
      </Box>
      <Box marginBottom={3}>
        <Box marginBottom={1}>
          <RhTypography variant="h4">Update Service End Date</RhTypography>
        </Box>
        <ChangeServiceEndDateForm
          disabled={customer.canChangeServiceEndDate()}
        />
      </Box>
      {!inProduction && (
        <Box marginBottom={3}>
          <Box marginBottom={1}>
            <RhTypography variant="h4">Mock Inbound EDI</RhTypography>
          </Box>
          {validInboundEDI !== null && (
            <GenerateInboundEDIForm validInboundEDI={validInboundEDI} />
          )}
        </Box>
      )}
      <Box marginBottom={2}>
        <Box marginBottom={1}>
          <RhTypography variant="h4">
            EDI Message History (
            {customer.ediMessages && customer.ediMessages.length})
          </RhTypography>
        </Box>
        <Box>
          <EDIMessages messages={customer.ediMessages} />
        </Box>
      </Box>
    </Box>
  );
};
