import { api } from "@common/api/api";
import { setAveragedBillingMonthlyChargeApi } from "@common/api/customerApi";
import { maskCurrency, unmaskCurrency } from "@common/forms/currency.mask";
import { Customer } from "@common/models/Customer.model";
import { CustomerBillingPreferences } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDayPicker } from "@design-system/components/RhDayPicker/RhDayPicker";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Box,
  DialogContent,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { FC, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface AveragedBillingFormValues {
  averagedBillingMonthlyCharge: string;
}

interface AccountBillingFieldsProps {
  customer: Customer;
}

interface EtfAmountFormValues {
  etfAmount: string;
}

export const AccountBillingFields: FC<AccountBillingFieldsProps> = ({
  customer,
}) => {
  const [averagedBillingToggle, setAveragedBillingToggle] = useState<boolean>(
    customer.averagedBilling
  );
  const [averagedBillingModal, setAveragedBillingModal] = useState<boolean>(
    false
  );
  const [pickDueDateModal, setPickDueDateModal] = useState<boolean>(false);
  const initialValues: AveragedBillingFormValues = {
    averagedBillingMonthlyCharge: "",
  };
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const onAveragedBillingSubmit = async (values: AveragedBillingFormValues) => {
    const averagedBillingMonthlyCharge = Number(
      unmaskCurrency(values.averagedBillingMonthlyCharge)
    );
    const [error] = await handleAjaxCall(
      setAveragedBillingMonthlyChargeApi(
        customer.id,
        averagedBillingMonthlyCharge
      )
    );

    if (error) {
      flash.error("Error setting Average Billing");
      return;
    }
    setAveragedBillingModal(false);
    setAveragedBillingToggle(true);
    dispatch(
      customerUpdated({
        averagedBilling: true,
        averagedBillingMonthlyCharge,
      })
    );
    flash.success("Updated customer's averaged billing monthly charge");
  };

  const [updatingPreferences, setUpdatingPreferences] = useState<boolean>(
    false
  );

  const [preferences, setPreferences] = useState<CustomerBillingPreferences>({
    invoiceByPrint: customer.invoiceByPrint,
    autopay: customer.autopay,
    averagedBilling: customer.averagedBilling,
    pickedBillingDueDay: customer.pickedBillingDueDay,
    applyEtfPoints: customer.applyEtfPoints,
    etfAmount: customer.etfAmount,
  });

  const updateETFPreferences = async (
    updatedPreferences: Partial<CustomerBillingPreferences>
  ) => {
    if (updatingPreferences) {
      return;
    }
    setUpdatingPreferences(true);

    const [error, updatedCustomer] = await handleAjaxCall(
      api.customers.update(customer.id, updatedPreferences)
    );

    if (error) {
      // if there is an error, then just switch back to original preferences
      flash.error("Error updating ETF preferences");
    } else if (updatedCustomer) {
      dispatch(customerUpdated(updatedCustomer));
      flash.success("Preferences updated");
    }
    setUpdatingPreferences(false);
  };

  const updatePreferences = async (
    updatedPreferences: Partial<CustomerBillingPreferences>
  ) => {
    if (updatingPreferences) {
      return;
    }
    setUpdatingPreferences(true);

    const oldPreferences = { ...preferences };

    // optimistically set preferences while making request
    setPreferences({
      ...preferences,
      ...updatedPreferences,
    });

    const [error, updatedCustomer] = await handleAjaxCall(
      api.customers.update(customer.id, updatedPreferences)
    );

    if (error) {
      // if there is an error, then just switch back to original preferences
      setPreferences(oldPreferences);
      flash.error("Error updating billing preferences");
    } else if (updatedCustomer) {
      dispatch(customerUpdated(updatedCustomer));
      flash.success("Preferences updated");
    }
    setUpdatingPreferences(false);
  };

  const isSubmitETFButtonDisabled = ![null, "STARTED"].includes(
    customer.etfCreditStatus || null
  );

  const etfPointLabel = `${100 * Number(customer.etfAmount)} Points / $ ${
    customer.etfAmount || 0
  }`;

  return (
    <>
      {!customer.hasHistoricUsage && (
        <Box paddingY={1}>
          <FormControlLabel
            control={
              <Switch
                disabled={averagedBillingToggle}
                checked={averagedBillingToggle}
                onClick={() => {
                  if (averagedBillingToggle === false) {
                    setAveragedBillingModal(true);
                  }
                }}
              />
            }
            label="Average Billing:"
            labelPlacement="start"
          />
        </Box>
      )}
      {customer.averagedBillingMonthlyCharge && customer.averagedBilling && (
        <Box display="flex" marginLeft={2} paddingY={1} width={500}>
          <Box flex={1}>
            <RhTypography variant="body1">
              Averaged Billing Amount:
              <br />${customer.averagedBillingMonthlyCharge}
            </RhTypography>
          </Box>
          <Box flex={1}>
            <RhButton
              color="primary"
              variant="outlined"
              onClick={() => setAveragedBillingModal(true)}
            >
              Change
            </RhButton>
          </Box>
        </Box>
      )}
      <Box paddingY={1}>
        <FormControlLabel
          control={
            <Switch
              checked={!preferences.invoiceByPrint}
              onClick={() => {
                updatePreferences({
                  invoiceByPrint: !preferences.invoiceByPrint,
                });
              }}
            />
          }
          label="eBill:"
          labelPlacement="start"
        />
      </Box>
      <Box paddingY={1}>
        <FormControlLabel
          control={
            <Switch
              checked={preferences.autopay}
              onClick={() => {
                updatePreferences({
                  autopay: !preferences.autopay,
                });
              }}
            />
          }
          label="Auto Pay:"
          labelPlacement="start"
        />
      </Box>
      <Box paddingY={1}>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(preferences.pickedBillingDueDay)}
              onClick={() => {
                if (preferences.pickedBillingDueDay) {
                  updatePreferences({ pickedBillingDueDay: null });
                } else {
                  setPickDueDateModal(true);
                }
              }}
            />
          }
          label="Pick Your Own Due Date:"
          labelPlacement="start"
        />
        {preferences.pickedBillingDueDay && (
          <Box paddingLeft={4}>
            <RhTypography variant="caption">
              Monthly on {preferences.pickedBillingDueDay}
            </RhTypography>
          </Box>
        )}
      </Box>

      {customer.etfCreditStatus === "COMPLETED" ? (
        <Box marginLeft={2} marginBottom={2}>
          <RhTypography variant="body1">
            ETF Reimbursement Status: {etfPointLabel}
          </RhTypography>
        </Box>
      ) : (
        <>
          <Box marginLeft={2}>
            <RhTypography variant="body1">ETF Payment Amount</RhTypography>
          </Box>
          <Box marginLeft={2}>
            <Form<EtfAmountFormValues>
              initialValues={{ etfAmount: customer.etfAmount }}
              onSubmit={updateETFPreferences}
              validateOnBlur
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="flex" marginBottom={2} width={400}>
                    <Box flex={1} paddingRight={1}>
                      <RhTextField
                        name="etfAmount"
                        placeholder="Enter $ for ETF Fees"
                        type="text"
                        disabled={isSubmitETFButtonDisabled}
                      />
                    </Box>
                    <Box flex={1}>
                      <RhOutlineButton
                        type="submit"
                        color="secondary"
                        className="etfAmount__submit"
                        disabled={isSubmitETFButtonDisabled}
                      >
                        Submit
                      </RhOutlineButton>
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Box>

          <Box marginLeft={2} marginBottom={2}>
            <RhTypography variant="body1">
              ETF Reimbursement Status: {customer.etfCreditStatus}
            </RhTypography>
          </Box>
          <Box marginLeft={2}>
            <Box display="flex" marginBottom={2} width={400}>
              <Box flex={1} paddingRight={1}>
                <RhTypography variant="body1">{etfPointLabel}</RhTypography>
              </Box>
              <Box flex={1}>
                <RhOutlineButton
                  type="submit"
                  color="secondary"
                  className="etfCreditAmountPoints__submit"
                  onClick={(_) =>
                    updateETFPreferences({ applyEtfPoints: true })
                  }
                  disabled={!customer.etfAmount}
                >
                  Apply Points
                </RhOutlineButton>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {averagedBillingModal && (
        <RhDialog
          open
          onClose={() => {
            setAveragedBillingModal(false);
          }}
          size="medium"
        >
          <RhDialogTitle>Set the Averaged Billing Monthly Charge</RhDialogTitle>
          <DialogContent>
            <Form<AveragedBillingFormValues>
              initialValues={initialValues}
              onSubmit={onAveragedBillingSubmit}
              render={({ handleSubmit, form }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <RhTextField
                    autoFocus
                    name="averagedBillingMonthlyCharge"
                    format={maskCurrency}
                    inputMode="numeric"
                  />
                  <Box marginTop={2}>
                    <RhSubmitButton disallowPristineSubmit>Save</RhSubmitButton>
                  </Box>
                </form>
              )}
            />
          </DialogContent>
        </RhDialog>
      )}
      {pickDueDateModal && (
        <RhDialog
          open
          onClose={() => {
            setPickDueDateModal(false);
          }}
          size="medium"
        >
          <RhDialogTitle>Set Billing Due Date</RhDialogTitle>
          <RhDialogContentWithButtons
            primaryButton={{
              label: "Save",
              onClick: () => {
                updatePreferences({
                  pickedBillingDueDay: preferences.pickedBillingDueDay,
                });
                setPickDueDateModal(false);
              },
              isDisabled: preferences.pickedBillingDueDay === null,
            }}
            secondaryButton={{
              label: "Cancel",
              onClick: () => {
                setPreferences({
                  ...preferences,
                  pickedBillingDueDay: null,
                });
                setPickDueDateModal(false);
              },
            }}
          >
            <RhDayPicker
              onDaySelected={(selected) => {
                setPreferences({
                  ...preferences,
                  pickedBillingDueDay: selected,
                });
              }}
            />
          </RhDialogContentWithButtons>
        </RhDialog>
      )}
    </>
  );
};
