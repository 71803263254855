import { Customer } from "@common/models/Customer.model";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { CustomerTable } from "@ops/components/CustomerTable/CustomerTable";
import { useGetAllCustomers } from "@ops/hooks/useGetAllCustomers";
import React, { FC } from "react";

export const CustomersPage: FC = () => {
  const { requestMonitor, data: customers, error } = useGetAllCustomers();
  const flash = useRhFlash();

  if (requestMonitor.isWaiting) {
    return <RhCircularProgress />;
  }
  if (requestMonitor.didFail || !customers) {
    if (error) {
      flash.error("Unable to get all customers. Please try again.");
    }
    return null;
  }
  return <CustomerTable customers={customers.map((c) => new Customer(c))} />;
};
