import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useSignInPageStyles = makeStyles<typeof rhythmTheme, "root">(
  (theme) =>
    createStyles({
      root: {
        marginTop: "10vh",
        width: 300,
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(5),
        },
      },
    })
);
