import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { Customer } from "@common/models/Customer.model";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { PremiseFormValues } from "@ops/components/UpdateEsiId/UpdateEsiId";
import { UpdateEsiIdFields } from "@ops/components/UpdateEsiId/UpdateEsiIdFields";
import React, { FC } from "react";
import { Form } from "react-final-form";

export interface UpdateEsiIdDialogProps {
  customer: Customer;
  open: boolean;
  onSubmit: (esiId: PremiseFormValues) => void;
}

export const MeterFormValidator = generateValidationErrorCollector<PremiseFormValues>(
  {
    esiId: [isRequired],
  }
);

export const UpdateEsiIdDialog: FC<UpdateEsiIdDialogProps> = ({
  open,
  onSubmit,
}) => {
  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="md">
      <DialogTitle>Update ESI ID</DialogTitle>
      <DialogContent>
        <Form<PremiseFormValues>
          onSubmit={onSubmit}
          validate={MeterFormValidator}
          validateOnBlur
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <UpdateEsiIdFields />
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};
