import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidDateTodayOrFuture } from "@common/forms/validators";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { CustomerDetailTextField } from "@ops/components/CustomerDetailTextField/CustomerDetailTextField";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

interface ChangeServiceStartDateFormValues {
  serviceStartDate: string;
}
const changeServiceStartDateValidator = generateValidationErrorCollector<ChangeServiceStartDateFormValues>(
  {
    serviceStartDate: [isRequired, isValidDateTodayOrFuture],
  }
);

export const ChangeServiceStartDateForm: FC = () => {
  const customerState = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);
  const dispatch = useDispatch();
  const flash = useRhFlash();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const [error, updatedCustomer] = await handleAjaxCall(
      api.customers.update(customer.id, values)
    );

    if (error) {
      flash.error("Error updating service start day. Please try again");
      return;
    }

    dispatch(customerUpdated(updatedCustomer as CustomerType));
    flash.success("Success!");
  };

  const disabled = !customer.canChangeServiceStartDate();

  return (
    <Form<ChangeServiceStartDateFormValues>
      onSubmit={onSubmit}
      validate={changeServiceStartDateValidator}
      validateOnBlur
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box display="flex" marginBottom={2} width={400}>
            <Box flex={1} paddingRight={1}>
              <CustomerDetailTextField
                disabled={disabled}
                name="serviceStartDate"
                placeholder="New Service Start Date"
                type="date"
              >
                New Service Start Date
              </CustomerDetailTextField>
            </Box>
            <Box flex={1}>
              <RhOutlineButton
                type="submit"
                color="secondary"
                className="changeServiceStartDate__submit"
                data-testid="changeServiceStartDate__submit"
                disabled={disabled}
              >
                Submit
              </RhOutlineButton>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};
