import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { FieldError } from "@ops/components/FieldError/FieldError";
import { FieldValidator } from "final-form";
import React, { FC } from "react";
import { useField } from "react-final-form";

// NOTE: copied from "portal/components/RhTextField"

interface CustomerDetailTextFieldProps<Type> {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (value: any, name: string) => any;
  name: string;
  placeholder: string;
  type?: "text" | "email" | "date" | "tel";
  validate?: FieldValidator<Type>;
}

export const CustomerDetailTextField: FC<
  CustomerDetailTextFieldProps<string>
> = ({
  autoComplete = "off",
  autoFocus,
  children,
  disabled,
  format,
  name,
  placeholder,
  type,
  validate,
}) => {
  const { input, meta } = useField<string>(name, {
    format,
    validate,
    type,
  });
  const hasErrors =
    (meta.touched && Boolean(meta.error)) ||
    (meta.submitFailed && Boolean(meta.submitError));

  return (
    <>
      <RhTextInput
        required
        {...input}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled || meta.submitting}
        error={hasErrors}
      >
        {children}
      </RhTextInput>
      {hasErrors && !meta.dirtySinceLastSubmit && (
        <FieldError>{meta.error}</FieldError>
      )}
    </>
  );
};
