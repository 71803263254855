import {
  formatCurrency,
  formatMonthDayYearDate,
  separateNumber,
} from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useGetRewardRedemptionHistory } from "@ops/hooks/useGetRewardRedemptionHistory";
import React, { FC } from "react";

interface RafRewardHistoryTableProps {
  affiliateId: string;
}

export const RafRewardHistoryTable: FC<RafRewardHistoryTableProps> = ({
  affiliateId,
}) => {
  const {
    data: rewardRedemptionHistoryData,
    requestMonitor,
  } = useGetRewardRedemptionHistory(affiliateId);

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  if (requestMonitor.didFail || !rewardRedemptionHistoryData) {
    return (
      <RhTypography variant="h4">
        There was an issue fetching the rewards redemption history for this
        affiliate. Please try again.
      </RhTypography>
    );
  }

  return (
    <Box>
      <Box style={{ textAlign: "center" }} mb={2}>
        <RhTypography variant="h4">
          Reward Point Redemption History
        </RhTypography>
      </Box>

      {rewardRedemptionHistoryData.length === 0 ? (
        <RhTypography variant="h4">
          This affiliate has not redeemed any reward points yet.
        </RhTypography>
      ) : (
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Points Redeemed</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewardRedemptionHistoryData.map((rewardRedemptionHistory) => (
              <TableRow key={rewardRedemptionHistory.redeemedAt}>
                <TableCell>
                  {formatMonthDayYearDate(rewardRedemptionHistory.redeemedAt)}
                </TableCell>
                <TableCell>
                  {separateNumber(rewardRedemptionHistory.points)}
                </TableCell>
                <TableCell>
                  {formatCurrency(
                    rewardPointsToDollars(rewardRedemptionHistory.points)
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
