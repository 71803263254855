import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useCustomerSearchFormStyles } from "@ops/components/CustomerSearchForm/CustomerSearchForm.style";
import React, { FC, FormEvent, useState } from "react";

const MIN_SEARCH_TERM_LENGTH = 3;

interface CustomerSearchFormProps {
  onSubmit: (query: string) => void;
}

export const CustomerSearchForm: FC<CustomerSearchFormProps> = ({
  onSubmit,
}) => {
  const classes = useCustomerSearchFormStyles();

  const [query, setQuery] = useState<string>("");
  const ready = query.trim().length >= MIN_SEARCH_TERM_LENGTH;
  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    onSubmit(query);
    return false;
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.searchForm}>
        <RhTextInput
          name="query"
          placeholder="e.g. 'smith' or 'id:123'"
          autoFocus
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <RhOutlineButton
          className={classes.searchButton}
          size="small"
          color="primary"
          disabled={!ready}
          type="submit"
        >
          Search
        </RhOutlineButton>
      </form>
      <RhTypography variant="body3">
        Your query term will be matched against ESI ID, first name, last name
        and email and any partial matches will be shown in the table below. ESI
        ID matches will be exact matches. Name and email will use a partial
        (substring) match. e.g. Search for &ldquo;jon&rdquo; will return
        customers named &ldquo;Jonathan&rdquo; and customers with emails like
        &ldquo;mynameisjon@example.com&rdquo;.
      </RhTypography>
      <RhTypography variant="body3">
        To search by customer ID, use the syntax &ldquo;id:123&rdquo;
      </RhTypography>

      <RhTypography variant="body3">
        You must include at least 3 characters in your search term.
      </RhTypography>
    </div>
  );
};
