import { useRhAccordionDetailsStyles } from "@design-system/components/RhAccordionDetails/RhAccordionDetails.style";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { AccordionDetails } from "@material-ui/core";
import React, { FC } from "react";

export const RhAccordionDetails: FC = ({ children }) => {
  const classes = useRhAccordionDetailsStyles();

  return (
    <AccordionDetails classes={classes}>
      <RhTypography variant="body2" component="div">
        {children}
      </RhTypography>
    </AccordionDetails>
  );
};
