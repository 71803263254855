import { customerStateReducer } from "@ops/slices/customerSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ReducersMapObject } from "redux"; // eslint-disable-line import/no-extraneous-dependencies

export const reducers: ReducersMapObject = {
  customer: customerStateReducer,
};

export const store = configureStore({
  reducer: combineReducers(reducers),
});
