import { Version } from "@common/components/Version/Version";
import {
  PAGE_GUTTER,
  RH_HEADER_HEIGHT,
} from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { OpsHeader } from "@ops/components/OpsHeader/OpsHeader";
import { LoggedInRoutes } from "@ops/routes/LoggedIn.route";
import { LoggedOutRoutes } from "@ops/routes/LoggedOut.route";
import React from "react";

export const App = () => (
  <>
    <Box
      component="main"
      alignItems="center"
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      justifyContent="center"
      marginTop={RH_HEADER_HEIGHT}
      marginBottom={3}
      mx={3}
      paddingTop={4}
      maxWidth={`calc(100% - ${PAGE_GUTTER * 2}px)`}
    >
      <OpsHeader />
      <LoggedInRoutes />
      <LoggedOutRoutes />
    </Box>
    <Version />
  </>
);
