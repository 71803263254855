import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { OPS_CUSTOMER_DETAIL_DIALOG_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { CustomerDetailTextField } from "@ops/components/CustomerDetailTextField/CustomerDetailTextField";
import React, { FC } from "react";

export const UpdateEsiIdFields: FC = () => {
  return (
    <Box width={OPS_CUSTOMER_DETAIL_DIALOG_WIDTH} marginBottom={2}>
      <Box padding={2}>
        <CustomerDetailTextField name="esiId" placeholder="ESI ID">
          ESI ID
        </CustomerDetailTextField>
      </Box>

      <Box padding={2}>
        <RhSubmitButton>Update</RhSubmitButton>
      </Box>
    </Box>
  );
};
