import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { Box } from "@material-ui/core";
import React, { Children, FC } from "react";

const FieldSpacing: FC = ({ children }) => (
  <>
    {Children.map(children, (child) => (
      <Box my={3}>{child}</Box>
    ))}
  </>
);

export const AffiliateFields: FC = () => {
  return (
    <FieldSpacing>
      <RhFlexBox>
        <Box flexBasis="50%" paddingRight="15px">
          <RhTextField name="firstName">First Name</RhTextField>
        </Box>
        <Box flex={1}>
          <RhTextField name="lastName">Last Name</RhTextField>
        </Box>
      </RhFlexBox>
      <RhFlexBox>
        <Box flexBasis="75%" paddingRight="15px">
          <RhTextField name="email" type="email">
            Email
          </RhTextField>
        </Box>
        <Box flex={1}>
          <RhTextField
            format={maskPhone}
            parse={unmaskPhone}
            name="phone"
            type="tel"
            inputMode="numeric"
            placeholder="555-555-5555"
          >
            Phone Number
          </RhTextField>
        </Box>
      </RhFlexBox>
      <RhFlexBox>
        <Box flexBasis="75%" paddingRight="15px">
          <RhTextField name="addressLine1">Street Address</RhTextField>
        </Box>
        <Box flex={1}>
          <RhTextField name="unitNumber">Unit #</RhTextField>
        </Box>
      </RhFlexBox>
      <RhFlexBox>
        <Box flexBasis="66%" paddingRight="15px">
          <RhTextField name="city">City</RhTextField>
        </Box>
        <Box flexBasis="20%" paddingRight="15px">
          <RhTextField name="state">State</RhTextField>
        </Box>
        <Box flexBasis="14%" flex={1}>
          <RhTextField name="zipCode">Zip Code</RhTextField>
        </Box>
      </RhFlexBox>
      <RhTextField name="organizationName">Organization Name</RhTextField>
      <RhTextField name="einTaxId">EIN or Tax ID</RhTextField>
    </FieldSpacing>
  );
};
