import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { Customer } from "@common/models/Customer.model";
import { CustomerSearchResponseType } from "@common/types/customerTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { CustomerSearchForm } from "@ops/components/CustomerSearchForm/CustomerSearchForm";
import { CustomerTable } from "@ops/components/CustomerTable/CustomerTable";
import React, { FC, useState } from "react";

export const CustomerSearchPage: FC = () => {
  const flash = useRhFlash();
  const [
    { data: customers, requestMonitor: status, error },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<CustomerSearchResponseType>([]);

  const [lastQuery, setLastQuery] = useState<string>("");

  const handleSubmit = (query: string) => {
    setPending();
    setLastQuery(query);
    api.customers
      .search(query)
      .then((data) => {
        setSuccess(data);
      })
      .catch((err) => {
        setFailure(err);
        flash.error(err.statusText);
      });
  };

  if (!customers) {
    return null;
  }

  const renderResults = (): JSX.Element => {
    if (customers.length) {
      return (
        <CustomerTable customers={customers.map((c) => new Customer(c))} />
      );
    } else {
      return (
        <>
          <RhTypography>
            {lastQuery
              ? `Found no matches for "${lastQuery}"`
              : "Type something in the search box above and click Search"}
          </RhTypography>
          {status.didFail && (
            <RhTypography color="error">
              The search request failed. {error?.statusText}
            </RhTypography>
          )}
        </>
      );
    }
  };

  return (
    <>
      <CustomerSearchForm onSubmit={handleSubmit} />
      {status.isPending ? <RhCircularProgress /> : renderResults()}
    </>
  );
};
