import { useRhPaperStyles } from "@design-system/components/RhPaper/RhPaper.style";
import { Paper, PaperProps } from "@material-ui/core";
import React, { FC, RefAttributes } from "react";

type RhPaperProps = Omit<PaperProps, "elevation">;

export const RhPaper: FC<
  RhPaperProps & RefAttributes<unknown>
> = React.forwardRef((props, ref) => {
  const classes = useRhPaperStyles();

  return (
    <Paper ref={ref} className={classes.root} variant="outlined" {...props}>
      {props.children}
    </Paper>
  );
});
