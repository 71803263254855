import { LanguagePreferenceType } from "@common/types/customerTypes";
import { RhCheckbox } from "@design-system/components/RhCheckbox/RhCheckbox";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OPS_CUSTOMER_DETAIL_DIALOG_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { CustomerDetailTextField } from "@ops/components/CustomerDetailTextField/CustomerDetailTextField";
import { CustomerMailingAddressFormValues } from "@ops/types/customerContactTypes";
import React, { FC } from "react";
import { useField, useFormState } from "react-final-form";

export const LanguageOption: FC<{ value: LanguagePreferenceType }> = ({
  value,
}) => {
  const { input } = useField<LanguagePreferenceType>("languagePreference", {
    type: "radio",
    value,
  });

  return (
    <Box alignItems="center" display="flex" px={1} py={2}>
      <RhRadioInput id={`language-${value}`} {...input} />
      <RhTypography>{value}</RhTypography>
    </Box>
  );
};

export const UpdateMailingAddressFields: FC = () => {
  const {
    values: { mailingAddressSameAsServiceAddress },
  } = useFormState<CustomerMailingAddressFormValues>();

  return (
    <Box width={OPS_CUSTOMER_DETAIL_DIALOG_WIDTH} marginBottom={2}>
      <Box padding={2}>
        <RhCheckbox
          name="mailingAddressSameAsServiceAddress"
          label="Same as service address"
        />
      </Box>
      <Box padding={2}>
        <CustomerDetailTextField
          autoComplete="address-line1"
          name="addressLine1"
          placeholder="Street address"
          disabled={mailingAddressSameAsServiceAddress}
        >
          Street Address
        </CustomerDetailTextField>
      </Box>
      <Box padding={2}>
        <CustomerDetailTextField
          autoComplete="address-line2"
          name="unitNumber"
          placeholder="Unit #"
          disabled={mailingAddressSameAsServiceAddress}
        >
          Unit #
        </CustomerDetailTextField>
      </Box>
      <Box padding={2}>
        <CustomerDetailTextField
          autoComplete="address-level2"
          name="city"
          placeholder="City"
          disabled={mailingAddressSameAsServiceAddress}
        >
          City
        </CustomerDetailTextField>
      </Box>
      <Box padding={2}>
        <CustomerDetailTextField
          autoComplete="address-level1"
          name="state"
          placeholder="State"
          disabled={mailingAddressSameAsServiceAddress}
        >
          State
        </CustomerDetailTextField>
      </Box>
      <Box padding={2}>
        <CustomerDetailTextField
          autoComplete="postal-code"
          name="zipCode"
          placeholder="Zip Code"
          disabled={mailingAddressSameAsServiceAddress}
        >
          Zip Code
        </CustomerDetailTextField>
      </Box>
      <Box padding={2}>
        <RhSubmitButton>Submit Changes</RhSubmitButton>
      </Box>
    </Box>
  );
};
