import { maskPhone } from "@common/forms/phone.mask";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { OPS_CUSTOMER_DETAIL_DIALOG_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { CustomerDetailTextField } from "@ops/components/CustomerDetailTextField/CustomerDetailTextField";
import { CustomerLanguagePreference } from "@ops/components/CustomerLanguagePreference/CustomerLanguagePreference";
import React from "react";

export const UpdateContactInfoFields = () => (
  <Box width={OPS_CUSTOMER_DETAIL_DIALOG_WIDTH} marginBottom={2}>
    <Box padding={2}>
      <CustomerDetailTextField
        autoComplete="given-name"
        autoFocus
        name="firstName"
        placeholder="First name"
      >
        First name
      </CustomerDetailTextField>
    </Box>
    <Box padding={2}>
      <CustomerDetailTextField
        autoComplete="family-name"
        name="lastName"
        placeholder="Last name"
      >
        Last name
      </CustomerDetailTextField>
    </Box>
    <Box padding={2}>
      <CustomerDetailTextField
        autoComplete="email"
        name="email"
        placeholder="Email"
        type="email"
      >
        Email
      </CustomerDetailTextField>
    </Box>
    <Box padding={2}>
      <CustomerDetailTextField
        autoComplete="phone"
        format={maskPhone}
        name="phone"
        type="tel"
        placeholder="___-___-____"
      >
        Phone
      </CustomerDetailTextField>
    </Box>
    <Box padding={2}>
      <CustomerLanguagePreference />
    </Box>
    <Box padding={2}>
      <RhSubmitButton>Submit Changes</RhSubmitButton>
    </Box>
  </Box>
);
