import { useRhAccordionSummaryStyles } from "@design-system/components/RhAccordionSummary/RhAccordionSummary.style";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ChevronDown } from "@design-system/icons";
import { AccordionSummary } from "@material-ui/core";
import React, { FC } from "react";

interface RhAccordionSummaryProps {
  summary: string;
}
export const RhAccordionSummary: FC<RhAccordionSummaryProps> = ({
  summary,
}) => {
  const classes = useRhAccordionSummaryStyles();

  return (
    <AccordionSummary
      aria-label="Expand"
      expandIcon={<ChevronDown title={summary} />}
      classes={classes}
    >
      <RhTypography variant="body2" color="primary" fontWeight="Medium">
        {summary}
      </RhTypography>
    </AccordionSummary>
  );
};
