import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useRafRewardsSectionStyle = makeStyles<
  typeof rhythmTheme,
  "textContainer"
>((theme) =>
  createStyles({
    textContainer: {
      flex: 1,
      marginRight: theme.spacing(1),
    },
  })
);
