import {
  EDIMessage,
  orderByCreatedAtAndIdDesc,
} from "@common/models/EDIMessage.model";
import { RhAccordion } from "@design-system/components/RhAccordion/RhAccordion";
import { RhAccordionDetails } from "@design-system/components/RhAccordionDetails/RhAccordionDetails";
import { RhAccordionSummary } from "@design-system/components/RhAccordionSummary/RhAccordionSummary";
import isEmpty from "lodash/isEmpty";
import React, { FC } from "react";

export interface EDIMessageProps {
  message: EDIMessage;
}

export interface EDIMessagesProps {
  messages: EDIMessage[] | undefined;
}

export const EDIMessageDetail: FC<EDIMessageProps> = ({ message }) => {
  return (
    <RhAccordion data-testid="ediMessageDetail">
      <RhAccordionSummary summary={message.formatSummary()} />
      <RhAccordionDetails>
        <pre>
          <code>{JSON.stringify(message.jsonMessage, null, 2)}</code>
        </pre>
      </RhAccordionDetails>
    </RhAccordion>
  );
};

export const EDIMessages: FC<EDIMessagesProps> = ({ messages }) => {
  if (isEmpty(messages)) {
    return null;
  }
  return (
    <>
      {(messages || []).sort(orderByCreatedAtAndIdDesc).map((message) => (
        <EDIMessageDetail key={`ediMessage:${message.id}`} message={message} />
      ))}
    </>
  );
};
