import { api } from "@common/api/api";
import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { GeneratedInvoiceType } from "@common/types/paymentMethodTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { GeneratedInvoiceUploadDialog } from "@ops/components/GeneratedInvoiceUploadDialog/GeneratedInvoiceUploadDialog";
import { customerDetailPath } from "@ops/routes/routePaths";
import dayjs from "dayjs";
import React, { FC } from "react";
import { Link } from "react-router-dom";

type InvoiceTableRowProps = {
  invoice: GeneratedInvoiceType;
};

type InvoiceTableProps = {
  invoices: GeneratedInvoiceType[];
  customerId: IdType;
};

const jsonDownload = async (id: IdType) => {
  const blob = await api.billing.generatedInvoice.download(id);
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");

  link.href = url;
  const fileName = `OpsInvoiceJsonOverrides_${id}_${dayjs().format(
    ISO8601_DATE_FORMAT
  )}.json`;

  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

const InvoiceTableRow: FC<InvoiceTableRowProps> = ({ invoice }) => {
  return (
    <TableRow data-testid={`invoiceTable__row:${invoice.id}`}>
      <TableCell>{invoice.id}</TableCell>
      <TableCell>{invoice.zuoraInvoiceId}</TableCell>
      <TableCell>{invoice.pdfRendered ? "Yes" : "No"}</TableCell>
      <TableCell>{invoice.mailed ? "Yes" : "No"}</TableCell>
      <TableCell>{invoice.autoPay ? "Yes" : "No"}</TableCell>
      <TableCell>{invoice.lobLetterId}</TableCell>
      <TableCell>
        {dayjs(invoice.createdAt).format(ISO8601_DATE_FORMAT)}
      </TableCell>
      <TableCell>
        {dayjs(invoice.updatedAt).format(ISO8601_DATE_FORMAT)}
      </TableCell>
      <TableCell>
        <RhButton
          color="primary"
          size="small"
          onClick={() => jsonDownload(invoice.id)}
        >
          Download
        </RhButton>
      </TableCell>
      <TableCell>
        <GeneratedInvoiceUploadDialog id={invoice.id} />
      </TableCell>
    </TableRow>
  );
};

export const GeneratedInvoiceTable: FC<InvoiceTableProps> = ({
  invoices,
  customerId,
}) => {
  return (
    <div>
      <Link
        to={customerDetailPath(customerId)}
        data-testid={`invoiceTable__link:${customerId}`}
      >
        Back to Customer : {customerId}
      </Link>
      <Table size="small" data-testid="invoiceTableContainer">
        <TableHead data-testid="invoiceTableHeader">
          <TableRow>
            <TableCell>Generated Invoice ID</TableCell>
            <TableCell>Zuora Invoice Id</TableCell>
            <TableCell>Have PDF</TableCell>
            <TableCell>Mailed</TableCell>
            <TableCell>Autopay</TableCell>
            <TableCell>Lob Letter Id</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Updated On</TableCell>
            <TableCell>Original Json</TableCell>
            <TableCell>Ops Inputs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice: GeneratedInvoiceType) => (
            <InvoiceTableRow invoice={invoice} key={`row-${invoice.id}`} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
