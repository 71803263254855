import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { EventKey } from "@design-system/enums/EventKey.enum";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React, { FC, useState } from "react";

import { useRhDayPickerStyles } from "./RhDayPicker.style";

interface RhDayPickerProps {
  onDaySelected: (day: number) => void;
}

export const RhDayPicker: FC<RhDayPickerProps> = ({ onDaySelected }) => {
  const classes = useRhDayPickerStyles();
  const [selectedDay, setSelectedDay] = useState<number | null>(null);

  let day = 1;
  const rows: JSX.Element[] = [];

  for (let weekNumber = 1; weekNumber <= 4; weekNumber += 1) {
    const cells: JSX.Element[] = [];

    for (let dayNumber = 1; dayNumber <= 7; dayNumber += 1) {
      // Capture current value in loop of day as part of the closure
      const newDay = day;

      cells.push(
        <TableCell
          align="center"
          key={day}
          tabIndex={0}
          className={
            selectedDay === day ? classes.selectedDay : classes.notSelectedDay
          }
          onKeyUp={(event) => {
            if (
              event.key === EventKey.SpaceBar ||
              event.key === EventKey.Enter
            ) {
              onDaySelected(newDay);
              setSelectedDay(newDay);
            }
          }}
          onClick={() => {
            onDaySelected(newDay);
            setSelectedDay(newDay);
          }}
        >
          <RhTypography
            variant="h4"
            className={
              selectedDay === day
                ? classes.selectedDayText
                : classes.notSelectedDayText
            }
          >
            {day}
          </RhTypography>
        </TableCell>
      );

      day += 1;
    }

    rows.push(
      <TableRow className={classes.tableRowRoot} key={weekNumber}>
        {cells}
      </TableRow>
    );
  }

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
