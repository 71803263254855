import { useRhCardStyles } from "@design-system/components/RhCard/RhCard.styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import classNames from "classnames";
import React, { FC, ReactNode, RefAttributes } from "react";

interface RhCardProps {
  className?: string;
  title?: ReactNode;
  noShadow?: boolean;
  headerClassName?: string;
}

export const RH_CARD_HEADER_HEIGHT_PX = 52;

export const RhCard: FC<
  RhCardProps & RefAttributes<unknown>
> = React.forwardRef(
  (
    {
      children,
      className = "",
      title = null,
      noShadow = false,
      headerClassName = "",
    },
    ref
  ) => {
    const classes = useRhCardStyles();

    return (
      <Card
        ref={ref}
        className={`${classes.container} ${
          noShadow ? "" : classes.containerShadow
        } ${className}`}
        elevation={0}
      >
        {title && (
          <CardHeader
            className={classNames(classes.header, headerClassName)}
            title={title}
          />
        )}
        <CardContent className={classes.content}>{children}</CardContent>
      </Card>
    );
  }
);
