import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhPaperStyles = makeStyles<typeof rhythmTheme, "root">(
  (theme) =>
    createStyles({
      root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
      },
    })
);
