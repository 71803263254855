import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { FC } from "react";
import { useField } from "react-final-form";

export interface RhCheckboxProps extends CheckboxProps {
  autoFocus?: boolean;
  className?: string | undefined;
  disabled?: boolean;
  label: string;
  name: string;
}

export const RhCheckbox: FC<RhCheckboxProps> = ({
  autoFocus,
  className,
  disabled,
  name,
  label,
  ...restProps
}) => {
  const { input } = useField<boolean>(name, {
    type: "checkbox",
  });

  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          color="primary"
          autoFocus={autoFocus}
          disabled={disabled}
          id={name}
          {...restProps}
          {...input}
        />
      }
      label={label}
    />
  );
};
