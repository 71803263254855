import { rewardsApi } from "@common/api/rewardsApi";
import { formatCurrency, separateNumber } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { useRafRewardsSectionStyle } from "@ops/components/RafRewardsSection/RafRewardsSection.style";
import { useGetAffiliateRewards } from "@ops/hooks/useGetAffiliateRewards";
import React, { FC, useEffect, useState } from "react";

interface RafRewardsSectionProps {
  affiliateId: string;
}

export const RafRewardsSection: FC<RafRewardsSectionProps> = ({
  affiliateId,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [value, setValue] = useState<number>(0);
  const [
    confirmationBtnDisabled,
    setConfirmationBtnDisabled,
  ] = useState<boolean>(false);
  const classes = useRafRewardsSectionStyle();
  const { data: rewards, requestMonitor, error } = useGetAffiliateRewards(
    affiliateId
  );
  const flash = useRhFlash();

  useEffect(() => {
    setBalance(rewards?.balance ?? -1);
    setValue(rewards?.value ?? 0);
  }, [rewards]);

  if (requestMonitor.isWaiting || balance === null) {
    return <RhCircularProgress />;
  }

  if (error || !rewards || balance < 0) {
    return (
      <RhTypography variant="h4">
        There was an issue fetching the rewards balance for this affiliate.
        Please try again.
      </RhTypography>
    );
  }

  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };
  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };
  const redeemAllPoints = () => {
    setConfirmationBtnDisabled(true);

    rewardsApi.affiliates
      .redeemRewardedPoints(affiliateId, balance)
      .then(({ remainingBalance }) => {
        setBalance(remainingBalance);
        setValue(remainingBalance / 100.0);
        closeConfirmation();
        flash.success(
          `Successfully redeemed ${balance} points for this affiliate.`
        );
      })
      .catch(() => {
        flash.error(
          "There seems to be an issue with redeeming points. Please try again later."
        );
      })
      .finally(() => {
        setConfirmationBtnDisabled(false);
      });
  };

  return (
    <RhFlexBox
      flexDirection="column"
      justifyContent="space-between"
      height="220px"
    >
      <RhTypography variant="h4">Points Balance</RhTypography>

      <Box marginBottom={3}>
        <RhFlexBox>
          <div className={classes.textContainer}>
            <RhTypography variant="h1" component="span">
              {separateNumber(balance)}
            </RhTypography>
            <RhTypography variant="body1" component="span">
              {" Points"}
            </RhTypography>

            <Box mb={1}>
              <RhTypography variant="body1">
                {`${formatCurrency(value)} cash value`}
              </RhTypography>
            </Box>
            <RhTypography variant="body2" color="textSecondary">
              {`${separateNumber(rewards.lifetime)} lifetime Points earned`}
            </RhTypography>
            <RhTypography variant="body2" color="textSecondary">
              {`${rewards.referredCustomers} customers referred`}
            </RhTypography>
          </div>
        </RhFlexBox>
      </Box>
      <RhButton
        color="primary"
        disabled={balance === 0}
        onClick={openConfirmation}
      >
        Redeem Points
      </RhButton>

      <RhDialog open={isConfirmationOpen}>
        <RhDialogTitle>Redeem All Points?</RhDialogTitle>
        <RhDialogContentWithButtons
          primaryButton={{
            label: confirmationBtnDisabled
              ? "Redeeming Points"
              : "Redeem Points",
            onClick: redeemAllPoints,
            isDisabled: confirmationBtnDisabled,
          }}
          secondaryButton={{
            label: "Cancel",
            onClick: closeConfirmation,
            isDisabled: confirmationBtnDisabled,
          }}
        >
          <RhTypography variant="subtitle2" color="textSecondary">
            {`This will redeem all ${balance} points for this affiliate. Would you like to continue?`}
          </RhTypography>
        </RhDialogContentWithButtons>
      </RhDialog>
    </RhFlexBox>
  );
};
