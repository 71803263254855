import { LanguagePreferenceType } from "@common/types/customerTypes";
import { AllSupportedPortalLanguages } from "@common/types/languageTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { Box, RadioGroup } from "@material-ui/core";
import React, { FC } from "react";
import { useField } from "react-final-form";

export const LanguageOption: FC<{ value: LanguagePreferenceType }> = ({
  value,
}) => {
  const { input } = useField<LanguagePreferenceType>("languagePreference", {
    type: "radio",
    value,
  });

  return (
    <Box alignItems="center" display="flex" px={1} py={2}>
      <RhRadioInput id={`language-${value}`} {...input} />
      <RhTypography>{value}</RhTypography>
    </Box>
  );
};

export const CustomerLanguagePreference: FC = () => (
  <RhFlexBox
    flexDirection="column"
    padding={2}
    border={`1px solid ${rhythmTheme.palette.grey[300]}`}
  >
    <RhTypography variant="subtitle1">Language Preference</RhTypography>
    <RadioGroup>
      <RhFlexBox>
        {AllSupportedPortalLanguages.map((languageCode) => (
          <LanguageOption key={languageCode} value={languageCode} />
        ))}
      </RhFlexBox>
    </RadioGroup>
  </RhFlexBox>
);
