import { api } from "@common/api/api";
import { Customer } from "@common/models/Customer.model";
import { IdType } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { AccountBilling } from "@ops/components/AccountBilling/AccountBilling";
import { AccountEDI } from "@ops/components/AccountEDI/AccountEDI";
import { AccountInfo } from "@ops/components/AccountInfo/AccountInfo";
import { AccountNav } from "@ops/components/AccountNav/AccountNav";
import { AccountRewards } from "@ops/components/AccountRewards/AccountRewards";
import { AccountService } from "@ops/components/AccountService/AccountService";
import { AccountStatus } from "@ops/components/AccountStatus/AccountStatus";
import { useValidInboundEDI } from "@ops/hooks/useValidInboundEDI";
import { useCustomerDetailPageStyles } from "@ops/pages/CustomerDetailPage/CustomerDetailPage.style";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import { CustomerStateType, customerUpdated } from "@ops/slices/customerSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const CustomerDetailPage = () => {
  const { id } = useParams<{ id: IdType }>();
  const customerState: CustomerStateType = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);
  const classes = useCustomerDetailPageStyles();
  const dispatch = useDispatch();

  const {
    requestMonitor,
    data: inboundEDIData,
    error,
    triggerRefetch,
  } = useValidInboundEDI(id);
  const flash = useRhFlash();
  const customerId = customer.id;
  const ediMessageLength = customerState?.ediMessages?.length;

  useEffect(() => {
    api.customers.retrieve(id).then((data) => {
      dispatch(customerUpdated(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (customerId && customerId === id) {
      triggerRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, ediMessageLength]);

  if (requestMonitor.didFail && error && Boolean(customerId)) {
    flash.error("Unable to get customer information. Please try again.");
  }

  const validInboundEDI = inboundEDIData?.valid || null;

  if (!customer.id || customer.id !== id) {
    return <RhCircularProgress />;
  }

  return (
    <RhFlexBox flexDirection="row">
      <Box className={classes.accountNavFlexContainer}>
        <Box className={classes.accountNavContainer}>
          <AccountNav />
        </Box>
      </Box>
      <Box className={classes.accountContainer}>
        <AccountInfo />
        <AccountService />
        <AccountStatus />
        <AccountBilling />
        <AccountRewards />
        <AccountEDI validInboundEDI={validInboundEDI} />
      </Box>
    </RhFlexBox>
  );
};
