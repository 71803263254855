import { affiliateApi } from "@common/api/affiliateApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { AffiliateType } from "@common/types/affiliateType";
import { RhApiError } from "@common/types/errorTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import {
  AFFILIATE_UNIQUE_EMAIL_ERROR_CODE,
  VOUCHERIFY_ERROR,
} from "@ops/constants/affiliates.constants";
import { AffiliateFields } from "@ops/pages/NewAffiliatePage/NewAffiliateFields";
import { affiliatesPath } from "@ops/routes/routePaths";
import { FORM_ERROR } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useHistory } from "react-router-dom";

export type AffiliateFormValues = Omit<
  AffiliateType,
  "id" | "referralCode" | "createdAt"
>;

export const affiliateFormValidator = generateValidationErrorCollector<AffiliateFormValues>(
  {
    firstName: [isRequired],
    lastName: [isRequired],
    email: [isRequired, isValidEmail],
    phone: [],
    addressLine1: [],
    unitNumber: [],
    city: [],
    state: [],
    zipCode: [],
    organizationName: [],
    einTaxId: [],
  }
);

export const NewAffiliatePage: FC = () => {
  const history = useHistory();
  const flash = useRhFlash();

  const initialValues = Object.freeze<AffiliateFormValues>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    unitNumber: "",
    city: "",
    state: "",
    zipCode: "",
    organizationName: "",
    einTaxId: "",
  });

  const onSubmit = (affiliateData: AffiliateFormValues) => {
    return affiliateApi
      .create(affiliateData)
      .then(() => {
        history.push(affiliatesPath());
      })
      .catch((error: RhApiError) => {
        if (error.data?.errorCode === AFFILIATE_UNIQUE_EMAIL_ERROR_CODE) {
          return { email: "Affiliate Email must be unique." };
        }
        if (error.data?.errorCode === VOUCHERIFY_ERROR) {
          flash.error(
            `An error occurred with Voucherify while creating affiliate: ${affiliateData.email}. Please try again.`
          );
          history.push(affiliatesPath());
        } else {
          flash.error("Unable to create affiliate. Please try again.");
          return { [FORM_ERROR]: "Something went wrong" };
        }
      });
  };

  return (
    <Box>
      <Box style={{ textAlign: "center" }} mb={4}>
        <RhTypography variant="h1">New Affiliate</RhTypography>
      </Box>
      <RhFlexBox justifyContent="center" width={500}>
        <Form<AffiliateFormValues>
          initialValues={initialValues}
          validate={affiliateFormValidator}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form noValidate style={{ width: "100%" }} onSubmit={handleSubmit}>
              <AffiliateFields />
              <RhSubmitButton size="large" fullWidth>
                Create Affiliate
              </RhSubmitButton>
            </form>
          )}
        />
      </RhFlexBox>
    </Box>
  );
};
