import { CustomerType } from "@common/types/customerTypes";
import { createAction, createReducer } from "@reduxjs/toolkit";

export const CUSTOMER_UPDATED = "ops/customer_updated";

// Types
export type CustomerStateType = CustomerType;

interface CustomerUpdatedAction {
  type: typeof CUSTOMER_UPDATED;
  payload: CustomerStateType;
}

// Actions
export const customerUpdated = createAction(
  CUSTOMER_UPDATED,
  (payload: Partial<CustomerStateType>) => ({
    payload,
  })
);

// Handlers
export const customerUpdatedHandler = (
  state: CustomerStateType,
  action: CustomerUpdatedAction
) => {
  return { ...state, ...action.payload };
};

// Reducer
export const initialCustomerState: CustomerStateType = {} as CustomerStateType;

export const customerStateReducer = createReducer<CustomerStateType>(
  initialCustomerState,
  {
    [customerUpdated.type]: customerUpdatedHandler,
  }
);
