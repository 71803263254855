import { formatPhoneNumber } from "@common/forms/formatters";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OPS_CUSTOMER_DETAIL_BUTTON_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { UpdateContactInfo } from "@ops/components/UpdateContactInfo/UpdateContactInfo";
import { UpdateMailingAddress } from "@ops/components/UpdateMailingAddress/UpdateMailingAddress";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";

export const AccountInfo: FC = () => {
  const customerState = useSelector(selectCustomerState);

  if (!customerState) {
    return <RhCircularProgress />;
  }
  const customer = new Customer(customerState as CustomerType);

  return (
    <Box marginBottom={3} id="info">
      <Box marginBottom={2}>
        <RhTypography variant="h1">{customer.fullName}</RhTypography>
        <RhTypography variant="body1">{customer.email}</RhTypography>
        <RhTypography variant="body1">
          {formatPhoneNumber(customer.phone)}
        </RhTypography>
      </Box>
      <RhFlexBox>
        <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} padding={2}>
          <UpdateContactInfo customer={customer} />
        </Box>
        <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} padding={2}>
          <UpdateMailingAddress customer={customer} />
        </Box>
      </RhFlexBox>
    </Box>
  );
};
