import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { OktaAuthOptions } from "@okta/okta-auth-js";
import { allEnvApplicationSettings } from "@ops/settings/env";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const AXIOS_BASE_URL = envApplicationSettings.axiosBaseUrl;
export const AXIOS_TIMEOUT_MS: number = envApplicationSettings.axiosTimeoutMs;

export const OKTA_CONFIG = Object.freeze<OktaAuthOptions>({
  clientId: envApplicationSettings.oktaClientId,
  issuer: envApplicationSettings.oktaIssuer,
  redirectUri: envApplicationSettings.oktaRedirectUri,
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
});

export const SENTRY_CONFIG = Object.freeze({
  dsn: envApplicationSettings.sentryDSN,
  release: envApplicationSettings.sentryRelease,
  tracesSampleRate: envApplicationSettings.sentryTracesSampleRate,
});

export const ZUORA_URL = envApplicationSettings.zuoraSandboxBaseUrl;
