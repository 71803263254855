import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAffiliateDetailPageStyles = makeStyles<
  typeof rhythmTheme,
  "contentContainer"
>((theme) =>
  createStyles({
    contentContainer: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: 700,
    },
  })
);
