import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, Link } from "@material-ui/core";
import { AccountBillingFields } from "@ops/components/AccountBilling/AccountBillingFields";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import { ZUORA_URL } from "@ops/settings/config";
import React, { FC } from "react";
import { useSelector } from "react-redux";

export const AccountBilling: FC = () => {
  const customerState = useSelector(selectCustomerState);
  const customer = new Customer(
    customerState as CustomerType,
    ZUORA_URL as string
  );

  if (!customerState) {
    return null;
  }

  return (
    <Box marginBottom={3} id="billing">
      <Box marginBottom={1}>
        <RhTypography variant="h2">Billing</RhTypography>
      </Box>
      <Box>
        <RhTypography variant="body1">
          Current Plan: {customer.currentOrder.title}
        </RhTypography>
        <RhTypography variant="body1">
          Utility: {customer.currentOrder.utilityName}
        </RhTypography>
        <RhTypography variant="body1">
          Term: {customer.currentOrder.termMonths}m
        </RhTypography>
        <RhTypography variant="body1">
          Start Date: {customer.currentOrder.startDate}
        </RhTypography>
        <RhTypography variant="body1">
          End Date: {customer.currentOrder.endDate}
        </RhTypography>
        <RhTypography variant="body1">
          Billing Account #:{" "}
          {customer.zuoraLink() && (
            <Link target="_blank" href={customer.zuoraLink()}>
              {customer.billingAccountNumber}
            </Link>
          )}
        </RhTypography>
      </Box>
      <AccountBillingFields customer={customer} />
    </Box>
  );
};
