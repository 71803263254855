import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { HTMLInputEvent } from "@design-system/types/domEventTypes";
import Input from "@material-ui/core/Input";
import React, { FC, useState } from "react";

type GeneratedInvoiceUploadDialogProps = {
  id: IdType;
};

const backendFileIdentifier = "invoice_json";

export const GeneratedInvoiceUploadDialog: FC<GeneratedInvoiceUploadDialogProps> = ({
  id,
}) => {
  const flash = useRhFlash();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [jsonFile, setJsonFile] = useState<File>();

  const handleSubmit = async () => {
    if (jsonFile) {
      try {
        await api.billing.generatedInvoice.upload(
          id,
          jsonFile,
          backendFileIdentifier
        );

        setDialogIsOpen(false);
        flash.success(
          `Invoice File ${jsonFile.name} was successfully uploaded.`
        );
      } catch (error) {
        const errorMsg = `Failed to upload file ${jsonFile.name}. Please check the data.`;

        flash.error(errorMsg);
      }
    }
  };

  const handleChange = (event: HTMLInputEvent) => {
    if (event && event.target.files?.length) {
      const file = event?.target.files[0];
      const { name } = file;
      const pattern = /.json$/;

      if (pattern.test(name) === false) {
        flash.error("File upload must be in JSON file format");
        setJsonFile(undefined);
        return;
      }
      setJsonFile(file);
    }
  };

  return (
    <>
      <RhDialog open={dialogIsOpen} onClose={() => {}} size="medium">
        <RhDialogTitle>Json Invoice Overrides for id: {id}</RhDialogTitle>
        <RhDialogContentWithButtons
          primaryButton={{
            label: "Save",
            onClick: () => {
              handleSubmit();
            },
          }}
          secondaryButton={{
            label: "Cancel",
            onClick: () => {
              setDialogIsOpen(false);
            },
          }}
        >
          <div>
            <form>
              <Input
                onChange={handleChange}
                type="file"
                id="JsonInvoiceUploadInput"
                aria-label="JsonInvoiceUploadInput"
              />
            </form>
          </div>
        </RhDialogContentWithButtons>
      </RhDialog>
      <RhButton
        color="primary"
        size="small"
        onClick={() => setDialogIsOpen(true)}
      >
        Upload
      </RhButton>
    </>
  );
};
