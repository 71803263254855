import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { Theme, createStyles } from "@material-ui/core/styles";

type StyleKeys = "select";

export const useGenerateInboundEDIFormStyles = makeStyles<Theme, StyleKeys>(
  (_theme) =>
    createStyles({
      select: {
        marginRight: 10,
        marginTop: 10,
        width: 220,
      },
    })
);
