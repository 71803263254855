import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAffiliateReferralTableStyles = makeStyles<
  typeof rhythmTheme,
  "tableRow"
>((theme) =>
  createStyles({
    tableRow: {
      "&:hover": {
        "-moz-transform": "scale(1.01)",
        "-o-transform": "scale(1.01)",
        "-webkit-transform": "scale(1.01)",
        backgroundColor: theme.palette.primary[50],
        cursor: "pointer",
        transition: "background-color 500ms, transform 500ms",
      },
      transition: "border 250ms, transform 250ms",
    },
  })
);
