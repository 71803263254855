import { affiliateApi } from "@common/api/affiliateApi";
import { RhApiError } from "@common/types/errorTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { AFFILIATE_UNIQUE_EMAIL_ERROR_CODE } from "@ops/constants/affiliates.constants";
import { useGetOneAffiliate } from "@ops/hooks/useGetOneAffiliate";
import { AffiliateFields } from "@ops/pages/NewAffiliatePage/NewAffiliateFields";
import {
  AffiliateFormValues,
  affiliateFormValidator,
} from "@ops/pages/NewAffiliatePage/NewAffiliatePage";
import { affiliatesPath } from "@ops/routes/routePaths";
import { FORM_ERROR } from "final-form";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useHistory, useParams } from "react-router-dom";

export const EditAffiliatePage: FC = () => {
  const history = useHistory();
  const flash = useRhFlash();
  const { id } = useParams<{ id: string }>();

  const {
    requestMonitor,
    data: remoteAffiliateData,
    error: requestError,
  } = useGetOneAffiliate(id);

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  if (requestMonitor.didFail || !remoteAffiliateData) {
    if (requestError) {
      flash.error("Unable to get affiliate. Please try again.");
    }
    return null;
  }

  const initialValues = Object.freeze<AffiliateFormValues>(remoteAffiliateData);

  const onSubmit = (affiliateData: AffiliateFormValues) => {
    return affiliateApi
      .update(id, affiliateData)
      .then(() => {
        history.push(affiliatesPath());
      })
      .catch((error: RhApiError) => {
        if (error.data?.errorCode === AFFILIATE_UNIQUE_EMAIL_ERROR_CODE) {
          return { email: "Affiliate Email must be unique." };
        } else {
          flash.error("Unable to create affiliate. Please try again.");
          return { [FORM_ERROR]: "Something went wrong" };
        }
      });
  };

  return (
    <Box>
      <Box style={{ textAlign: "center" }} mb={4}>
        <RhTypography variant="h1">Edit Affiliate</RhTypography>
      </Box>
      <RhFlexBox justifyContent="center" width={500}>
        <Form<AffiliateFormValues>
          initialValues={initialValues}
          validate={affiliateFormValidator}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form noValidate style={{ width: "100%" }} onSubmit={handleSubmit}>
              <AffiliateFields />
              <RhSubmitButton size="large" fullWidth>
                Save Affiliate
              </RhSubmitButton>
            </form>
          )}
        />
      </RhFlexBox>
    </Box>
  );
};
