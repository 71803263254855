import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import {
  AccordionDetailsClassKey,
  Theme,
  createStyles,
} from "@material-ui/core";

export const useRhAccordionDetailsStyles = makeStyles<
  Theme,
  Extract<AccordionDetailsClassKey, "root">
>((theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(1)}px ${theme.spacing(7)}px ${theme.spacing(
        1
      )}px 0`,
    },
  })
);
