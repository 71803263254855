import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useAccountRewardsStyle = makeStyles<
  typeof rhythmTheme,
  | "date"
  | "description"
  | "pointsAmount"
  | "row"
  | "rowElement"
  | "table"
  | "pointsValue"
>((theme) =>
  createStyles({
    date: {
      flexBasis: "10%",
    },
    description: {
      flexBasis: "60%",
      [theme.breakpoints.down("xs")]: {
        flexBasis: "50%",
      },
    },
    pointsAmount: {
      color: theme.palette.grey[500],
      flexBasis: "20%",
      justifyContent: "flex-end",
      textAlign: "right",
    },
    pointsValue: {
      flexBasis: "10%",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        flexBasis: "20%",
      },
    },
    row: {
      alignItems: "center",
      borderBottom: borders[100],
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    rowElement: {
      display: "flex",
      paddingRight: theme.spacing(2),
    },
    table: {
      flexDirection: "column",
      width: "100%",
    },
  })
);
