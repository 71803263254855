import { api } from "@common/api/api";
import { Customer } from "@common/models/Customer.model";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhSelectField } from "@design-system/components/RhSelectField/RhSelectField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { DialogContent } from "@material-ui/core";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { FC } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface EditEnrollmentFormValues {
  status: string;
}

interface EnrollmentStatusEditFormProps {
  customer: Customer;
  enrollmentStatuses: string[];
  isOpen: boolean;
  handleClose: () => void;
}

export const EditEnrollmentStatusForm: FC<EnrollmentStatusEditFormProps> = ({
  customer,
  enrollmentStatuses,
  isOpen,
  handleClose,
}) => {
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const onSubmit = async ({ status }: EditEnrollmentFormValues) => {
    const [error] = await handleAjaxCall(
      api.edi.setEnrollmentStatus(customer.activePremise.id, status)
    );

    if (error) {
      flash.error("Error editing enrollment status. Please try again");
      return;
    }

    const [updatedCustomerError, updatedCustomer] = await handleAjaxCall(
      api.customers.retrieve(customer.id)
    );

    if (updatedCustomerError) {
      flash.error("Error getting customer's updated info. Please refresh site");
      return;
    } else if (updatedCustomer) {
      dispatch(customerUpdated(updatedCustomer));
    }

    handleClose();
  };

  return (
    <RhDialog open={isOpen} onClose={handleClose} size="medium">
      <RhDialogTitle>Override Enrollment Status</RhDialogTitle>
      <DialogContent>
        <Form<EditEnrollmentFormValues>
          onSubmit={onSubmit}
          initialValues={{ status: customer.enrollmentStatus }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <RhFlexBox flexDirection="row">
                <RhSelectField
                  name="status"
                  label="Enrollment Status"
                  options={enrollmentStatuses.map((value) => ({
                    label: value,
                    value,
                  }))}
                />
                <RhOutlineButton type="submit" color="secondary">
                  Submit
                </RhOutlineButton>
              </RhFlexBox>
            </form>
          )}
        />
      </DialogContent>
    </RhDialog>
  );
};
