import { IdType } from "@common/types/apiTypes";

const BASE_PATH = "/api/customers";

export const changePassword = (id: IdType) =>
  `${BASE_PATH}/${id}/change_password/`;
export const kwhUsage = (id: IdType) => `${BASE_PATH}/${id}/kwh_usage/`;
export const setAveragedBillingMonthlyCharge = (id: IdType) =>
  `${BASE_PATH}/${id}/set_averaged_billing_monthly_charge/`;
export const notificationChannelsList = () =>
  `${BASE_PATH}/notification_subscription_channels`;
export const notificationMessagesList = () =>
  `${BASE_PATH}/notification_subscription_types`;
