import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useAccountNavStyles } from "@ops/components/AccountNav/AccountNav.style";
import { customerInvoicePath } from "@ops/routes/routePaths";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import { CustomerStateType } from "@ops/slices/customerSlice";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const AccountNavItem: FC<{
  linkContainerClass: string;
}> = ({ linkContainerClass, children }) => (
  <li className={linkContainerClass}>
    <RhTypography component="div" variant="body1">
      {children}
    </RhTypography>
  </li>
);

export const AccountNav: FC = () => {
  const customerState: CustomerStateType = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);

  const classes = useAccountNavStyles({});
  const { pathname } = useLocation();

  const AccountNavItemValues = [
    { anchorTag: "#info", textContent: "Info" },
    { anchorTag: "#service", textContent: "Service" },
    { anchorTag: "#status", textContent: "Status" },
    { anchorTag: "#billing", textContent: "Billing" },
    { anchorTag: "#rewards", textContent: "Rewards" },
    { anchorTag: "#edi", textContent: "EDI" },
  ];

  return (
    <ul
      className={classes.navContainer}
      role="navigation"
      data-testid="Account__nav"
    >
      {AccountNavItemValues.map(({ anchorTag, textContent }, index) => (
        <AccountNavItem
          linkContainerClass={classes.linkContainer}
          // eslint-disable-next-line react/no-array-index-key
          key={`${anchorTag}${index}`}
        >
          <a href={`${pathname}${anchorTag}`} className={classes.link}>
            {textContent}
          </a>
        </AccountNavItem>
      ))}
      <AccountNavItem
        linkContainerClass={classes.linkContainer}
        key={`customer-${customer.id}`}
      >
        <Link to={customerInvoicePath(customer.id)} className={classes.link}>
          Invoices
        </Link>
      </AccountNavItem>
    </ul>
  );
};
