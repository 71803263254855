import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import {
  AccordionSummaryClassKey,
  Theme,
  createStyles,
} from "@material-ui/core";

export const useRhAccordionSummaryStyles = makeStyles<
  Theme,
  Extract<AccordionSummaryClassKey, "root" | "content" | "expandIcon">
>((theme) =>
  createStyles({
    content: {
      "&.Mui-expanded": {
        margin: 0,
      },
      margin: 0,
    },
    expandIcon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1),
    },
    root: {
      "&.Mui-expanded": {
        margin: 0,
        minHeight: "unset",
      },
      minHeight: "unset",
      padding: 0,
    },
  })
);
