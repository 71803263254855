import { SecureRoute, useOktaAuth } from "@okta/okta-react";
import { AccountInvoicesPage } from "@ops/pages/AccountInvoicesPage/AccountInvoicesPage";
import { AffiliateDetailPage } from "@ops/pages/AffiliateDetailPage/AffiliateDetailPage";
import { AffiliatesPage } from "@ops/pages/AffiliatesPage/AffiliatesPage";
import { CustomerDetailPage } from "@ops/pages/CustomerDetailPage/CustomerDetailPage";
import { CustomerSearchPage } from "@ops/pages/CustomerSearchPage/CustomerSearchPage";
import { CustomersPage } from "@ops/pages/CustomersPage/CustomersPage";
import { EditAffiliatePage } from "@ops/pages/EditAffiliatePage/EditAffiliatePage";
import { NewAffiliatePage } from "@ops/pages/NewAffiliatePage/NewAffiliatePage";
import {
  affiliateDetailPath,
  affiliateEditPath,
  affiliatesPath,
  customerDetailPath,
  customerInvoicePath,
  customerSearchPath,
  customersPath,
  homePath,
  logoutPath,
  newAffiliatePath,
} from "@ops/routes/routePaths";
import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const LoggedInRoutes: FC = () => {
  const { authService } = useOktaAuth();

  return (
    <Switch>
      <Redirect exact from={homePath()} to={customerSearchPath()} />
      <SecureRoute
        exact
        path={newAffiliatePath()}
        component={NewAffiliatePage}
      />
      <SecureRoute
        exact
        path={affiliateDetailPath(":id")}
        component={AffiliateDetailPage}
      />
      <SecureRoute
        exact
        path={affiliateEditPath(":id")}
        component={EditAffiliatePage}
      />
      <SecureRoute exact path={affiliatesPath()} component={AffiliatesPage} />
      <SecureRoute
        exact
        path={customerSearchPath()}
        component={CustomerSearchPage}
      />
      <SecureRoute
        exact
        path={customerDetailPath(":id")}
        component={CustomerDetailPage}
      />
      <SecureRoute
        exact
        path={customerInvoicePath(":id")}
        component={AccountInvoicesPage}
      />
      <SecureRoute exact path={customersPath()} component={CustomersPage} />
      <Route
        exact
        path={logoutPath()}
        render={() => {
          authService.logout(homePath());

          return null;
        }}
      />
    </Switch>
  );
};
