import { borders } from "@design-system/constants/borders";
import { boxShadows } from "@design-system/constants/boxShadows";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhMakeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhCardStyles = rhMakeStyles<
  typeof rhythmTheme,
  "header" | "container" | "containerShadow" | "content"
>((theme) =>
  createStyles({
    header: {
      padding: 0,
      borderBottom: borders[100],
      paddingBottom: theme.spacing(1.25),
      marginBottom: theme.spacing(2.5),
      fontSize: "16px",
      fontWeight: FontWeight.Bold,
      "& .MuiCardHeader-title": {
        fontSize: "16px",
        fontWeight: FontWeight.Bold,
      },
    },
    container: {
      width: "inherit",
      padding: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2.5),
      },
    },
    containerShadow: {
      border: borders[50],
      boxShadow: boxShadows.default,
      borderRadius: "6px",
    },
    content: {
      padding: 0,
    },
  })
);
