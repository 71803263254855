import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { AccordionClassKey } from "@material-ui/core";
import { Theme, createStyles } from "@material-ui/core/styles";

export const useRhAccordionStyles = makeStyles<
  Theme,
  Extract<AccordionClassKey, "root">
>((theme) =>
  createStyles({
    root: {
      "&.Mui-expanded": {
        margin: 0,
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(1.5)}px`,
      },
      "&:before": {
        opacity: 0,
      },
      borderBottom: borders[100],
      padding: `${theme.spacing(1)}px 0`,
    },
  })
);
