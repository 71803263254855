import { formatMonthDayYearDate } from "@common/utils/dataFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useAffiliateReferralTableStyles } from "@ops/components/AffiliateReferralTable/AffiliateReferralTable.style";
import { useGetAffiliateReferrals } from "@ops/hooks/useGetAffiliateReferrals";
import { customerDetailPath } from "@ops/routes/routePaths";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

interface AffiliateReferralTableProps {
  affiliateId: string;
}

export const AffiliateReferralTable: FC<AffiliateReferralTableProps> = ({
  affiliateId,
}) => {
  const history = useHistory();
  const { data: referralData, requestMonitor } = useGetAffiliateReferrals(
    affiliateId
  );
  const classes = useAffiliateReferralTableStyles();

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  if (requestMonitor.didFail || !referralData) {
    return (
      <RhTypography variant="h4">
        An error occurred while getting the referrals for this affiliate. Please
        try again later.
      </RhTypography>
    );
  }

  if (referralData.length === 0) {
    return (
      <RhTypography variant="h4">
        No referrals associated with this affiliate have signed up for Rhythm
        yet.
      </RhTypography>
    );
  }

  const goToCustomerDetailPage = (customerId: string) => {
    history.push(customerDetailPath(customerId));
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Email Address</TableCell>
          <TableCell>Customer Redeemed At</TableCell>
          <TableCell>Affiliate Rewarded At</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {referralData.map(
          ({
            refereeEmail,
            refereeId,
            refereeRedeemedReferralCodeAt,
            referrerRewardedPointsAt,
          }) => (
            <TableRow
              key={`${refereeEmail}${refereeId}`}
              onClick={() => goToCustomerDetailPage(refereeId)}
              className={classes.tableRow}
            >
              <TableCell>{refereeId}</TableCell>
              <TableCell>{refereeEmail}</TableCell>
              <TableCell>
                {refereeRedeemedReferralCodeAt
                  ? formatMonthDayYearDate(refereeRedeemedReferralCodeAt)
                  : "Not Redeemed"}
              </TableCell>
              <TableCell>
                {referrerRewardedPointsAt
                  ? formatMonthDayYearDate(referrerRewardedPointsAt)
                  : "Not Rewarded"}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
