import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidDateTodayOrFuture } from "@common/forms/validators";
import { Customer } from "@common/models/Customer.model";
import {
  AllEnrollmentTypes,
  EnrollmentType,
} from "@common/types/customerTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Box, RadioGroup } from "@material-ui/core";
import { CustomerDetailTextField } from "@ops/components//CustomerDetailTextField/CustomerDetailTextField";
import React, { FC } from "react";
import { Form, useField } from "react-final-form";

export interface ResubmitEnrollmentFormValues {
  newStartDate: string;
  enrollmentType: EnrollmentType;
}

const resubmitEnrollmentStartDateValidator = generateValidationErrorCollector<ResubmitEnrollmentFormValues>(
  {
    newStartDate: [isRequired, isValidDateTodayOrFuture],
  }
);

interface ResubmitEnrollmentFormProps {
  customer: Customer;
  handleSubmit: (formValues: ResubmitEnrollmentFormValues) => void;
}

export const EnrollmentTypeOption: FC<{ value: EnrollmentType }> = ({
  value,
}) => {
  const { input } = useField<EnrollmentType>("enrollmentType", {
    type: "radio",
    value,
  });

  return (
    <Box alignItems="center" display="flex" px={1} py={2}>
      <RhRadioInput id={value} {...input} />
      <RhTypography>{value}</RhTypography>
    </Box>
  );
};

export const ResubmitEnrollmentForm: FC<ResubmitEnrollmentFormProps> = ({
  customer,
  handleSubmit,
}) => {
  return (
    <Form<ResubmitEnrollmentFormValues>
      initialValues={{
        newStartDate: customer.confirmedStartDate || customer.serviceStartDate,
        enrollmentType: customer.enrollmentType,
      }}
      onSubmit={handleSubmit}
      validate={resubmitEnrollmentStartDateValidator}
      validateOnBlur
      render={({ handleSubmit: formHandleSubmit, form }) => (
        <form noValidate onSubmit={formHandleSubmit}>
          <Box display="flex" marginBottom={2} width={400}>
            <Box flex={1} paddingRight={1}>
              <CustomerDetailTextField
                name="newStartDate"
                placeholder="New Service Start Date"
                type="date"
              >
                New Service Start Date
              </CustomerDetailTextField>
              <RadioGroup>
                <RhFlexBox>
                  {AllEnrollmentTypes.map((enrollmentType) => (
                    <EnrollmentTypeOption
                      key={enrollmentType}
                      value={enrollmentType}
                    />
                  ))}
                </RhFlexBox>
              </RadioGroup>
              <Box marginTop={2}>
                <RhSubmitButton>Save</RhSubmitButton>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};
