import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useCustomerDetailPageStyles = makeStyles<
  typeof rhythmTheme,
  "accountNavContainer" | "accountNavFlexContainer" | "accountContainer"
>((theme) =>
  createStyles({
    accountContainer: {
      width: 815,
    },
    accountNavContainer: {
      position: "fixed",
      top: theme.spacing(20),
      width: "inherit",
    },
    accountNavFlexContainer: {
      marginRight: 23,
      width: 230,
    },
  })
);
