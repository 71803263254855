import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { AffiliateType } from "@common/types/affiliateType";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhPaper } from "@design-system/components/RhPaper/RhPaper";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { AffiliateReferralTable } from "@ops/components/AffiliateReferralTable/AffiliateReferralTable";
import { RafReferralUrl } from "@ops/components/RafReferralUrl/RafReferralUrl";
import { RafRewardHistoryTable } from "@ops/components/RafRewardHistoryTable/RafRewardHistoryTable";
import { RafRewardsSection } from "@ops/components/RafRewardsSection/RafRewardsSection";
import { useGetOneAffiliate } from "@ops/hooks/useGetOneAffiliate";
import { useAffiliateDetailPageStyles } from "@ops/pages/AffiliateDetailPage/AffiliateDetailPage.style";
import { affiliateEditPath } from "@ops/routes/routePaths";
import React, { FC } from "react";
import { useHistory, useParams } from "react-router-dom";

export const affiliateDetailTableColumns: (keyof AffiliateType)[] = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phone",
  "addressLine1",
  "unitNumber",
  "city",
  "state",
  "zipCode",
  "organizationName",
  "einTaxId",
];

export const AffiliateDetailPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const flash = useRhFlash();
  const classes = useAffiliateDetailPageStyles();
  const history = useHistory();

  const { requestMonitor, data: affiliateData, error } = useGetOneAffiliate(id);

  const goToAffiliateEditPage = () => {
    history.push(affiliateEditPath(id));
  };

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  if (requestMonitor.didFail || !affiliateData) {
    if (error) {
      flash.error("Unable to get affiliate's info. Please try again.");
    }
    return null;
  }

  return (
    <RhFlexBox flexDirection="column" alignItems="center">
      <Box mb={5}>
        <RhTypography variant="h1">
          Displaying Affiliate ID # {affiliateData.id}
        </RhTypography>
      </Box>
      <RhPaper className={classes.contentContainer}>
        <RhTypography variant="h3" fontWeight="Bold">
          Referral Url
        </RhTypography>
        {affiliateData.referralCode ? (
          <RafReferralUrl affiliate={affiliateData} />
        ) : (
          <RhTypography variant="h4">
            This affiliate does not have a referral code. Please try again
            later.
          </RhTypography>
        )}
      </RhPaper>
      <RhPaper className={classes.contentContainer}>
        <Box mb={2}>
          <RhTypography variant="h3">Rewards</RhTypography>
        </Box>
        <RhFlexBox flexDirection="row" justifyContent="space-evenly">
          <Box>
            <RafRewardsSection affiliateId={affiliateData.id} />
          </Box>
          <Box>
            <RafRewardHistoryTable affiliateId={affiliateData.id} />
          </Box>
        </RhFlexBox>
      </RhPaper>
      <RhPaper className={classes.contentContainer}>
        <Box mb={2}>
          <RhTypography variant="h3">Referrals</RhTypography>
        </Box>
        <AffiliateReferralTable affiliateId={affiliateData.id} />
      </RhPaper>
      <RhPaper className={classes.contentContainer}>
        {affiliateDetailTableColumns.slice(1).map((column) => (
          <RhFlexBox key={column} my={2}>
            <Box mr={1}>
              <RhTypography variant="h4" fontWeight="Bold">
                {convertCamelCaseToSentenceCase(column)}:
              </RhTypography>
            </Box>
            <Box>
              <RhTypography variant="h4">
                {affiliateData[column] || NOT_APPLICABLE_ABBREVIATION}
              </RhTypography>
            </Box>
          </RhFlexBox>
        ))}
      </RhPaper>

      <RhButton color="primary" onClick={goToAffiliateEditPage}>
        Edit Affiliate
      </RhButton>
    </RhFlexBox>
  );
};
