import { RhApiError } from "@common/types/errorTypes";

type ThenArg<T> = T extends Promise<infer U> ? U : T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleAjaxCall = <T = any>(promise: Promise<T>) => {
  type SuccessArg = ThenArg<typeof promise>;
  return promise
    .then((data: SuccessArg) => [null, data])
    .catch((error: RhApiError) => Promise.resolve([error, null])) as Promise<
    [RhApiError | null, null | SuccessArg]
  >;
};
