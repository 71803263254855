import queryString from "querystring";

import { AffiliateType } from "@common/types/affiliateType";
import { RHYTHM_MARKETING_URL } from "@ops/constants/url.constant";

const REFERRAL_CODE_QUERY_PARAM = "referralCode";
const UTM_COMMUNITY = "community";

export const signUpReferralUrl = ({
  firstName,
  lastName,
  referralCode,
}: AffiliateType) => {
  const queryParams = {
    [REFERRAL_CODE_QUERY_PARAM]: referralCode,
    utm_medium: UTM_COMMUNITY,
    utm_source: `${firstName} ${lastName}`,
    utm_campaign: referralCode,
  };

  return `${RHYTHM_MARKETING_URL}?${queryString.stringify(queryParams)}`;
};
