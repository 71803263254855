import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@design-system/theme/palette.colors";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

export const useRhDayPickerStyles = makeStyles<
  typeof rhythmTheme,
  | "tableRowRoot"
  | "selectedDay"
  | "selectedDayText"
  | "notSelectedDay"
  | "notSelectedDayText"
>((theme) =>
  createStyles({
    notSelectedDay: {
      "&:active:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: darkPurple[100],
        borderRadius: "50%",
      },
      backgroundColor: trueWhite,
    },
    notSelectedDayText: {
      color: grey[500],
    },
    selectedDay: {
      "&:hover": {
        cursor: "default",
      },
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      outline: "none",
    },
    selectedDayText: {
      color: trueWhite,
    },
    tableRowRoot: {
      "& td": {
        border: 0,
        cursor: "pointer",
      },
      "&:nth-child(2n+1)": {
        backgroundColor: trueWhite,
      },
    },
  })
);
