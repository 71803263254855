import { AffiliateType } from "@common/types/affiliateType";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { CopyIcon } from "@design-system/icons";
import Input from "@material-ui/core/Input";
import { useRafReferralUrlStyles } from "@ops/components/RafReferralUrl/RafReferralUrl.styles";
import { signUpReferralUrl } from "@ops/services/raf";
import React, { FC, useEffect, useRef, useState } from "react";

export const CLEAR_LINK_COPIED_TIME = 2000;

interface RafReferralUrlProps {
  affiliate: AffiliateType;
}

export const RafReferralUrl: FC<RafReferralUrlProps> = ({ affiliate }) => {
  const classes = useRafReferralUrlStyles();

  const copyLinkInputRef = useRef<HTMLElement>(null);
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const isDisabled = !affiliate.referralCode || isLinkCopied;

  useEffect(() => {
    if (isLinkCopied) {
      const timeoutId = setTimeout(() => {
        setIsLinkCopied(false);
      }, CLEAR_LINK_COPIED_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLinkCopied]);

  const copyTextToClipboard = () => {
    if (!isLinkCopied) {
      const referralUrlInput = copyLinkInputRef?.current?.firstElementChild as
        | HTMLInputElement
        | undefined;

      referralUrlInput?.select();
      document.execCommand("copy");
      setIsLinkCopied(true);
      document.getSelection()?.removeAllRanges(); // this deselects the text on chrome & safari
      referralUrlInput?.blur(); // this deselects the text on firefox & ie
    }
  };

  const buttonText = isLinkCopied ? "Copied" : "Copy Link";

  return (
    <RhFlexBox className={classes.root}>
      <Input
        className={classes.input}
        readOnly
        ref={copyLinkInputRef}
        value={signUpReferralUrl(affiliate)}
      />
      <RhButton
        className={classes.button}
        size="small"
        color="primary"
        disabled={isDisabled}
        onClick={copyTextToClipboard}
      >
        <CopyIcon className={classes.copyIcon} />
        {buttonText}
      </RhButton>
    </RhFlexBox>
  );
};
