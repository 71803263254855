import { RewardsType } from "@common/types/rewardsTypes";
import {
  formatCurrency,
  formatMonthDayYearDate,
} from "@common/utils/dataFormatters";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useAccountRewardsStyle } from "@ops/components/AccountRewards/AccountRewards.style";
import classNames from "classnames";
import React, { FC } from "react";

interface AccountRewardsTableProps {
  rewards: RewardsType | null;
}

export const AccountRewardsTable: FC<AccountRewardsTableProps> = ({
  rewards,
}) => {
  const classes = useAccountRewardsStyle();

  return (
    <RhCard title="Rewards History">
      {!rewards ? (
        <RhTypography>
          There was an issue fetching rewards history. Please try again.
        </RhTypography>
      ) : (
        <RhFlexBox className={classes.table}>
          {rewards.history.map(({ name, points, createdAt, value }) => (
            <RhFlexBox
              key={`${name}-${points}-${createdAt}`}
              className={classes.row}
            >
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.date)}
                variant="body1"
              >
                {formatMonthDayYearDate(createdAt)}
              </RhTypography>
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.description)}
                variant="body1"
              >
                {name || "Rhythm Points"}
              </RhTypography>
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.pointsAmount)}
                variant="body1"
              >
                {`${points} Points`}
              </RhTypography>
              <RhTypography
                component="div"
                className={classNames(classes.rowElement, classes.pointsValue)}
                variant="body1"
              >
                {formatCurrency(value)}
              </RhTypography>
            </RhFlexBox>
          ))}
        </RhFlexBox>
      )}
    </RhCard>
  );
};
