import { ReactComponent as RhythmLogo } from "@common/images/rhythmLogoDarkPurple.svg";
import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import { useOktaAuth } from "@okta/okta-react";
import {
  affiliatesPath,
  customerSearchPath,
  customersPath,
  logoutPath,
  signInPath,
} from "@ops/routes/routePaths";
import React from "react";
import { Link, NavLink } from "react-router-dom";

export const OpsHeader = () => {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  let items = [
    <NavLink key={affiliatesPath()} to={affiliatesPath()}>
      Affiliates
    </NavLink>,
    <NavLink key={customerSearchPath()} to={customerSearchPath()}>
      Customer Search
    </NavLink>,
    <NavLink key={customersPath()} to={customersPath()}>
      Customer List
    </NavLink>,
    <NavLink key={logoutPath()} to={logoutPath()}>
      Log Out
    </NavLink>,
  ];

  if (!isAuthenticated) {
    items = [
      <NavLink key={signInPath()} to={signInPath()}>
        Log in
      </NavLink>,
    ];
  }

  const logo = (
    <Link to={isAuthenticated ? customersPath() : signInPath()}>
      <RhythmLogo />
    </Link>
  );

  return <RhHeader items={items} logo={logo} />;
};
