import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { Customer } from "@common/models/Customer.model";
import { EnrollmentStatusesResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  CustomerType,
  EDIServiceOrderActionTypes,
} from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { OPS_CUSTOMER_DETAIL_BUTTON_WIDTH } from "@design-system/theme/style.constant";
import { Box, DialogContent } from "@material-ui/core";
import { EditEnrollmentStatusForm } from "@ops/components/EnrollmentStatusEditForm/EnrollmentStatusEditForm";
import {
  ResubmitEnrollmentForm,
  ResubmitEnrollmentFormValues,
} from "@ops/components/ResubmitEnrollmentForm/ResubmitEnrollmentForm";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const AccountStatus: FC = () => {
  const customerState = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const [enrollmentModal, setEnrollmentModal] = useState(false);

  const [
    enrollmentStatusModalIsOpen,
    setEnrollmentStatusModalIsOpen,
  ] = useState(false);

  const [
    { data, requestMonitor },
    { setSuccess, setPending, setFailure },
  ] = useAjaxState<EnrollmentStatusesResponseType>();

  useEffect(() => {
    setPending();

    api.edi
      .getEnrollmentStatuses()
      .then((enrollmentStatusesResponse: EnrollmentStatusesResponseType) => {
        setSuccess(enrollmentStatusesResponse);
      })
      .catch((error: RhApiError) => {
        setFailure(error);
        flash.error("Unable to get enrolment statuses. Please try again");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enrollmentStatues = data?.enrollmentStatuses ?? [];

  const handleOpenEditEnrollmentModal = () => {
    setEnrollmentStatusModalIsOpen(true);
  };

  const handleCloseEditEnrollmentModal = () => {
    setEnrollmentStatusModalIsOpen(false);
  };

  const handleSubmit = async (
    customerId: IdType,
    actionName: EDIServiceOrderActionTypes,
    values?: ResubmitEnrollmentFormValues | null
  ) => {
    const [error] = await handleAjaxCall(
      api.customers.ediMessages.create(customerId, {
        actionName,
        ...values,
      })
    );

    if (error) {
      flash.error("Issue updating customer account. Please try again.");
      return;
    }

    const [updatedCustomerError, updatedCustomer] = await handleAjaxCall(
      api.customers.retrieve(customerId)
    );

    if (updatedCustomerError) {
      flash.error(
        "Issue getting updated customer's information. Please refresh."
      );
      return;
    }

    dispatch(customerUpdated(updatedCustomer as CustomerType));
    flash.success("EDI message created");
  };

  return (
    <Box marginBottom={3} id="status">
      <Box marginBottom={1}>
        <RhTypography variant="h2">Status</RhTypography>
      </Box>

      <RhFlexBox
        marginBottom={2}
        padding={2}
        border={`1px solid ${rhythmTheme.palette.grey[300]}`}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <RhTypography variant="h3">Status</RhTypography>
          <RhTypography variant="body1">
            {customer.enrollmentStatus}
          </RhTypography>
        </Box>
        <Box>
          <RhOutlineButton
            disabled={!requestMonitor.didSucceed}
            type="submit"
            size="small"
            className="enrollment_status__submit"
            onClick={handleOpenEditEnrollmentModal}
          >
            {requestMonitor.didSucceed ? "Edit Status" : "Loading..."}
          </RhOutlineButton>
        </Box>
      </RhFlexBox>

      <RhFlexBox
        marginBottom={2}
        padding={2}
        border={`1px solid ${rhythmTheme.palette.grey[300]}`}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <RhTypography variant="h3">Enrollment Type</RhTypography>
          <RhTypography variant="body1">{customer.enrollmentType}</RhTypography>
        </Box>
      </RhFlexBox>

      <RhFlexBox
        marginBottom={2}
        padding={2}
        border={`1px solid ${rhythmTheme.palette.grey[300]}`}
        justifyContent="space-between"
        alignItems="center"
      >
        <RhFlexBox>
          <Box marginRight={2}>
            <RhTypography variant="h3">Service Start</RhTypography>
            <RhTypography variant="body1">
              {customer.confirmedStartDate ||
                customer.serviceStartDate ||
                customer.currentOrder.startDate}
            </RhTypography>
          </Box>
          <Box>
            <RhTypography variant="h3">Service End</RhTypography>
            <RhTypography variant="body1">
              {customer.serviceEndDate || ""}
            </RhTypography>
          </Box>
        </RhFlexBox>
      </RhFlexBox>
      <RhFlexBox
        paddingTop={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} marginBottom={2}>
            <RhOutlineButton
              size="small"
              fullWidth
              onClick={() => {
                handleSubmit(customer.id, "switch_cancel");
              }}
            >
              Cancel Switch
            </RhOutlineButton>
          </Box>
          <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} marginBottom={2}>
            <RhOutlineButton
              size="small"
              fullWidth
              onClick={() => {
                handleSubmit(customer.id, "switch_hold_create");
              }}
            >
              Trigger Switch Hold
            </RhOutlineButton>
          </Box>
          <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} marginBottom={2}>
            <RhOutlineButton
              size="small"
              fullWidth
              onClick={() => {
                handleSubmit(customer.id, "switch_hold_remove");
              }}
            >
              Remove Switch Hold
            </RhOutlineButton>
          </Box>
          <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} marginBottom={2}>
            <RhOutlineButton
              disabled={!customer.isEnrollmentEnrolled}
              size="small"
              fullWidth
              onClick={() => {
                handleSubmit(customer.id, "disconnect");
              }}
            >
              Trigger Disconnect
            </RhOutlineButton>
          </Box>
          <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} marginBottom={2}>
            <RhOutlineButton
              disabled={!customer.isDisconnected}
              size="small"
              fullWidth
              onClick={() => {
                handleSubmit(customer.id, "reconnect");
              }}
            >
              Trigger Reconnect
            </RhOutlineButton>
          </Box>
          <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH}>
            <RhOutlineButton
              disabled={!customer.isDisconnected}
              size="small"
              fullWidth
              onClick={() => {
                setEnrollmentModal(true);
              }}
            >
              Re-Submit Enrollment
            </RhOutlineButton>
          </Box>

          {enrollmentModal && (
            <RhDialog
              open
              onClose={() => {
                setEnrollmentModal(false);
              }}
              size="medium"
            >
              <RhDialogTitle>Select the New Start Date</RhDialogTitle>
              <DialogContent>
                <ResubmitEnrollmentForm
                  handleSubmit={(formValues) => {
                    handleSubmit(
                      customer.id,
                      "resubmit_enrollment",
                      formValues
                    );
                    setEnrollmentModal(false);
                  }}
                  customer={customer}
                />
              </DialogContent>
            </RhDialog>
          )}
        </Box>
      </RhFlexBox>
      <RhFlexBox>
        <EditEnrollmentStatusForm
          customer={customer}
          enrollmentStatuses={enrollmentStatues}
          isOpen={enrollmentStatusModalIsOpen}
          handleClose={handleCloseEditEnrollmentModal}
        />
      </RhFlexBox>
    </Box>
  );
};
