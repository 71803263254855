import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { Customer } from "@common/models/Customer.model";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { customerDetailPath } from "@ops/routes/routePaths";
import React, { FC } from "react";
import { Link } from "react-router-dom";

type CustomerTableRowProps = {
  customer: Customer;
};

type CustomerTableProps = {
  customers: Customer[];
};

const CustomerTableRow: FC<CustomerTableRowProps> = ({ customer }) => {
  return (
    <TableRow data-testid={`customerTable__row:${customer.id}`}>
      <TableCell>{customer.id}</TableCell>
      <TableCell>{customer.esiId || NOT_APPLICABLE_ABBREVIATION}</TableCell>
      <TableCell>
        <Link
          to={customerDetailPath(customer.id)}
          data-testid={`customerTable__emailLink:${customer.id}`}
        >
          {customer.email}
        </Link>
      </TableCell>
      <TableCell>{customer.fullName}</TableCell>
      <TableCell>{customer.confirmedStartDate}</TableCell>
      <TableCell>{customer.enrollmentType}</TableCell>
      <TableCell>{customer.enrollmentStatus}</TableCell>
    </TableRow>
  );
};

export const CustomerTable: FC<CustomerTableProps> = ({ customers }) => {
  return (
    <Table size="small">
      <TableHead data-testid="customerTableHeader">
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>ESI-ID</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Confirmed Start Date</TableCell>
          <TableCell>Enrollment Type</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customers.map((customer: Customer) => (
          <CustomerTableRow customer={customer} key={`row-${customer.id}`} />
        ))}
      </TableBody>
    </Table>
  );
};
