import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { GeneratedInvoiceResponseType } from "@common/types/paymentMethodTypes";
import { GeneratedInvoiceTable } from "@ops/components/GeneratedInvoiceTable/GeneratedInvoiceTable";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const AccountInvoicesPage: FC = () => {
  const [
    generatedInvoices,
    setGeneratedInvoices,
  ] = useState<GeneratedInvoiceResponseType>([]);

  useEffect(() => {
    api.billing.generatedInvoice
      .listCustomerInvoices(customerId)
      .then((data) => {
        setGeneratedInvoices(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useParams<{ id: IdType }>();
  const customerId = id;

  return (
    <GeneratedInvoiceTable
      invoices={generatedInvoices}
      customerId={customerId}
    />
  );
};
