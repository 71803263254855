import { AddressType } from "@common/types/customerTypes";

export class Address implements AddressType {
  deliveryLine1!: string;
  city!: string;
  state!: string;
  zipCode!: string;
  lastLine!: string;

  public constructor(address: AddressType) {
    Object.assign(this, address);
  }

  public get line1(): string {
    return this.deliveryLine1;
  }

  public get line2(): string {
    return this.lastLine;
  }

  public isEqual({ deliveryLine1, city, state, zipCode }: Address): boolean {
    return (
      this.deliveryLine1 === deliveryLine1 &&
      this.city === city &&
      this.state === state &&
      this.zipCode === zipCode
    );
  }
}
