import { LONG_DATE_TIME_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { EDIMessageType } from "@common/types/ediTypes";
import dayjs from "dayjs";

export class EDIMessage {
  public id: IdType = "";
  public createdAt!: string;
  public updatedAt!: string;
  public actionId!: string;
  public status!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public jsonMessage!: Record<string, any>;
  public outbound!: boolean;

  public constructor(init: EDIMessageType) {
    Object.assign(this, init);
  }

  public formatCreatedAt(): string {
    return dayjs(this.createdAt).format(LONG_DATE_TIME_FORMAT);
  }

  public direction(): string {
    return this.outbound ? "OUT" : "IN";
  }

  public formatSummary(): string {
    return `[${this.direction()}] ${
      this.actionId
    } -- ${this.formatCreatedAt()} -- 
    id:${this.id} ${this.status}`;
  }
}

export const orderByCreatedAtAndIdDesc = (
  a: EDIMessage,
  b: EDIMessage
): number => {
  if (b.createdAt === a.createdAt) {
    return parseInt(b.id, 10) - parseInt(a.id, 10);
  }
  return b.createdAt > a.createdAt ? 1 : -1;
};
