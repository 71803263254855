export enum OrderStatus {
  Enrolled = "ENROLLED",
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Uncreated = "UNCREATED",
}

export interface OrderType {
  orderNumber: string;
  termMonths: number;
  energyRate: number;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  status: OrderStatus;
  utilityName?: string;
  offersnapshotId: string;
}
