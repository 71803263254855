import { Address } from "@common/models/Address.model";
import { EDIMessage } from "@common/models/EDIMessage.model";
import { Meter } from "@common/models/Meter.model";
import { IdType } from "@common/types/apiTypes";
import {
  AddressFormType,
  CustomerType,
  EnrollmentType,
} from "@common/types/customerTypes";
import { PremiseType } from "@common/types/ediTypes";
import {
  SupportedPortalLanguage,
  englishLanguageCode,
} from "@common/types/languageTypes";
import { OrderStatus, OrderType } from "@common/types/orderTypes";

export class Customer {
  public activePremiseAddress: Address;
  public autopay!: boolean;
  public averagedBillingMonthlyCharge?: number;
  public averagedBilling!: boolean;
  public billingAccountId!: string;
  public billingAccountNumber!: string;
  public billingPaymentMethodId!: string;
  public confirmedStartDate?: string;
  public dateOfBirth!: string;
  public ediMessages: EDIMessage[];
  public email!: string;
  public enrollmentStatus!: string;
  public enrollmentType!: EnrollmentType;
  public esiId!: string;
  public firstName!: string;
  public fullName!: string;
  public hasHistoricUsage!: boolean;
  public id: IdType = "";
  public invoiceByEmail!: boolean;
  public invoiceByPrint!: boolean;
  public languagePreference: SupportedPortalLanguage;
  public lastName!: string;
  public mailingAddress?: AddressFormType;
  public mailingAddressSameAsServiceAddress!: boolean;
  public meter: Meter;
  public orders: OrderType[];
  public currentOrder: OrderType;
  public phone!: string;
  public serviceEndDate!: string;
  public serviceStartDate!: string;
  public source!: string;
  public zuoraUrl?: string;
  public pickedBillingDueDay!: number | null;
  public lifeSupport!: boolean;
  public referralToFriendCode!: string;
  public etfAmount!: string;
  public etfCreditStatus!: string;
  public applyEtfPoints?: boolean;
  public activePremise!: PremiseType;

  public constructor(
    {
      activePremiseAddress,
      ediMessages,
      languagePreference,
      mailingAddress,
      meter,
      orders,
      ...rest
    }: CustomerType,
    zuoraUrl?: string
  ) {
    Object.assign(this, rest);
    this.languagePreference = languagePreference || englishLanguageCode;
    this.ediMessages = (ediMessages || []).map((msg) => new EDIMessage(msg));
    this.orders = orders || [];
    this.meter = new Meter(meter || {});
    this.activePremiseAddress = new Address(activePremiseAddress);
    this.mailingAddress = mailingAddress;
    if (zuoraUrl) {
      this.zuoraUrl = zuoraUrl;
    }
    this.currentOrder =
      this.orders.find((order) => order.status !== OrderStatus.Expired) ||
      ({} as OrderType);

    this.mailingAddressSameAsServiceAddress = !this.mailingAddress;
  }

  public get hasOrder() {
    return Boolean(
      this.orders.find((order) => order.status !== OrderStatus.Expired)
    );
  }

  public get offerTermMonths() {
    return this.currentOrder.termMonths;
  }

  public isEnrollmentRequested(): boolean {
    return this.enrollmentStatus === "ENROLLMENT_REQUESTED";
  }

  public isEnrollmentAccepted(): boolean {
    return this.enrollmentStatus === "ENROLLMENT_ACCEPTED";
  }

  public isEnrollmentEnrolled(): boolean {
    return this.enrollmentStatus === "ENROLLMENT_ENROLLED";
  }
  public isDisconnected(): boolean {
    return this.enrollmentStatus === "STATUS_DISCONNECTED";
  }

  public isMoveOutRequested(): boolean {
    return this.enrollmentStatus === "MOVE_OUT_REQUESTED";
  }

  public isMoveOutAccepted(): boolean {
    return this.enrollmentStatus === "MOVE_OUT_ACCEPTED";
  }

  public isMoveOutDateChangeRequested(): boolean {
    return this.enrollmentStatus === "MOVE_OUT_DATE_CHANGE_REQUESTED";
  }

  public canChangeServiceEndDate(): boolean {
    return !this.canCancelDisabled();
  }

  public canCancelDisabled(): boolean {
    return (
      this.isMoveOutRequested() ||
      this.isMoveOutAccepted() ||
      this.isMoveOutDateChangeRequested()
    );
  }

  public canChangeServiceStartDate(): boolean {
    return this.isEnrollmentRequested() || this.isEnrollmentAccepted();
  }

  public isRegisteredInBilling(): boolean {
    return Boolean(this.billingAccountNumber);
  }

  public zuoraLink(): string | undefined {
    return this.billingAccountId && this.buildZuoraLink(this.billingAccountId);
  }

  private buildZuoraLink(id: string): string {
    return `${this.zuoraUrl}/apps/CustomerAccount.do?method=view&id=${id}`;
  }

  public zuoraUsageLink(): string | undefined {
    return (
      this.billingAccountNumber &&
      this.buildZuoraUsageLink(this.billingAccountNumber)
    );
  }

  private buildZuoraUsageLink(accountNumber: string): string {
    return `${this.zuoraUrl}/apps/Usages.do?method=list&FindKey=${accountNumber}`;
  }

  public get invoiceByEmailOnly(): boolean {
    return Boolean(this.invoiceByEmail && !this.invoiceByPrint);
  }
}
