import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  Validator,
  isRequired,
  isValidEmail,
  isValidPhoneNumber,
  isValidUSState,
  isValidZipCode,
} from "@common/forms/validators";
import {
  CustomerContactFormValues,
  CustomerMailingAddressFormValues,
} from "@ops/types/customerContactTypes";

export const customerContactFormValidator = generateValidationErrorCollector<
  CustomerContactFormValues
>({
  firstName: [isRequired],
  lastName: [isRequired],
  email: [isRequired, isValidEmail],
  phone: [isRequired, isValidPhoneNumber],
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyValidator = Validator<any>;

// Enforce a validator only if the mailing address is not the same as the service address.
export const ifMailingAddressDifferent: (
  otherValidator: AnyValidator
) => AnyValidator = (otherValidator) => {
  return (value, allValues, messageId): string | undefined => {
    if (!allValues?.mailingAddressSameAsServiceAddress) {
      return otherValidator(value, allValues, messageId);
    }
    return undefined;
  };
};

export const customerMailingAddressFormValidator = generateValidationErrorCollector<
  CustomerMailingAddressFormValues
>({
  mailingAddressSameAsServiceAddress: [],
  addressLine1: [ifMailingAddressDifferent(isRequired)],
  unitNumber: [],
  city: [ifMailingAddressDifferent(isRequired)],
  state: [
    ifMailingAddressDifferent(isRequired),
    ifMailingAddressDifferent(isValidUSState),
  ],
  zipCode: [
    ifMailingAddressDifferent(isRequired),
    ifMailingAddressDifferent(isValidZipCode),
  ],
});
