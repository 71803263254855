import { api } from "@common/api/api";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { UpdateMailingAddressDialog } from "@ops/components/UpdateMailingAddress/UpdateMailingAddressDialog";
import { customerUpdated } from "@ops/slices/customerSlice";
import { CustomerMailingAddressFormValues } from "@ops/types/customerContactTypes";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

interface UpdateMailingAddressProps {
  customer: Customer;
}

export const UpdateMailingAddress: FC<UpdateMailingAddressProps> = ({
  customer,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const handleSubmit = async (
    newMailingAddress: CustomerMailingAddressFormValues
  ) => {
    const [error, updatedCustomer] = await handleAjaxCall(
      api.customers.update(customer.id, newMailingAddress)
    );

    if (error) {
      flash.error("Unable to update mailing address. Please try again.");
      return;
    }

    dispatch(customerUpdated(updatedCustomer as CustomerType));
    flash.success("Customer contact info successfully updated.");

    setDialogOpen(false);
  };

  return (
    <>
      <RhOutlineButton onClick={(_event) => setDialogOpen(true)} fullWidth>
        Update Mailing Address
      </RhOutlineButton>
      <UpdateMailingAddressDialog
        customer={customer}
        open={dialogOpen}
        onSubmit={handleSubmit}
        setClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
};
