import { api } from "@common/api/api";
import { unmaskPhone } from "@common/forms/phone.mask";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { UpdateContactInfoDialog } from "@ops/components/UpdateContactInfoDialog/UpdateContactInfoDialog";
import { customerUpdated } from "@ops/slices/customerSlice";
import { CustomerContactFormValues } from "@ops/types/customerContactTypes";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

interface UpdateContactInfoProps {
  customer: Customer;
}

export const UpdateContactInfo: FC<UpdateContactInfoProps> = ({ customer }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const handleSubmit = async (newContactInfo: CustomerContactFormValues) => {
    const [error, updatedCustomer] = await handleAjaxCall(
      api.customers.update(customer.id, {
        ...newContactInfo,
        phone: unmaskPhone(newContactInfo.phone),
      })
    );

    if (error) {
      flash.error("Unable to update customer. Please try again.");
      return;
    }

    dispatch(customerUpdated(updatedCustomer as CustomerType));
    flash.success("Customer contact info successfully updated.");

    setDialogOpen(false);
  };

  return (
    <>
      <RhOutlineButton
        data-testid="customerDetail__changeContactDialog"
        onClick={(_event) => setDialogOpen(true)}
        fullWidth
      >
        Update Contact Info
      </RhOutlineButton>
      <UpdateContactInfoDialog
        customer={customer}
        open={dialogOpen}
        onSubmit={handleSubmit}
        setClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
};
