import { borders } from "@design-system/constants/borders";
import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

type AccountNavStyleClasses =
  | "linkContainer"
  | "link"
  | "navContainer"
  | "chevronContainer";

export const useAccountNavStyles = makeStyles<
  typeof rhythmTheme,
  AccountNavStyleClasses
>((theme) =>
  createStyles({
    chevronContainer: {
      color: theme.palette.grey[900],
      float: "right",
      paddingRight: theme.spacing(2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    linkContainer: {
      borderBottom: borders[200],
      marginBottom: theme.spacing(2),
      padding: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    navContainer: {
      listStyleType: "none",
      padding: 0,
    },
  })
);
