import { premisesUpdate } from "@common/api/ediUrls";
import { ajax } from "@common/services/ajax";
import { PremiseUpdateResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";

interface PremisePatchRequestType {
  meterId: string;
}

export const updatePremise: (
  id: IdType,
  premiseAttributes: PremisePatchRequestType
) => Promise<PremiseUpdateResponseType> = (id, premiseAttributes) => {
  return ajax.patch(premisesUpdate(id), premiseAttributes);
};
