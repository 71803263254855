import { MaskedPattern } from "imask";

const currencySymbol = "$";
const currencyMask = new MaskedPattern({
  mask: `${currencySymbol}num`,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ",",
      scale: 2,
      signed: false,
      padFractionalZeros: false,
      normalizeZeros: false,
      radix: ".",
      min: 0,
    },
  },
});

export const maskCurrency = (value: string) => {
  currencyMask.value = value ?? "";
  return currencyMask.value;
};

export const unmaskCurrency = (value: string) => {
  currencyMask.value = value ?? "";
  return currencyMask.unmaskedValue;
};
