import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { AffiliateType } from "@common/types/affiliateType";
import { formatMonthDayYearDate } from "@common/utils/dataFormatters";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useGetAllAffiliates } from "@ops/hooks/useGetAllAffiliates";
import { useAffiliatesPageStyles } from "@ops/pages/AffiliatesPage/AffiliatesPage.style";
import { affiliateDetailPath, newAffiliatePath } from "@ops/routes/routePaths";
import sortBy from "lodash/sortBy";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

export const affiliateTableColumns: (keyof AffiliateType)[] = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phone",
  "zipCode",
  "organizationName",
  "einTaxId",
];

export const AffiliatesPage: FC = () => {
  const history = useHistory();
  const flash = useRhFlash();

  const classes = useAffiliatesPageStyles();
  const { requestMonitor, data: affiliates, error } = useGetAllAffiliates();

  if (requestMonitor.isPending) {
    return <RhCircularProgress />;
  }

  if (requestMonitor.didFail || !affiliates) {
    if (error) {
      flash.error("Unable to get affiliates. Please try again.");
    }
    return null;
  }

  const goToAffiliateDetailPage = (affiliateId: string) => {
    history.push(affiliateDetailPath(affiliateId));
  };

  return (
    <Box>
      <RhFlexBox
        flexDirection="row"
        width="100%"
        justifyContent="flex-end"
        my={2}
      >
        <Box mx={2}>
          <RhLinkButton href={newAffiliatePath()} color="primary">
            New Affiliate
          </RhLinkButton>
        </Box>
      </RhFlexBox>
      <Table size="small">
        <TableHead>
          <TableRow>
            {affiliateTableColumns.map((column) => (
              <TableCell key={column}>
                {convertCamelCaseToSentenceCase(column)}
              </TableCell>
            ))}
            <TableCell>Sign Up Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(affiliates, "lastName").map((affiliate) => (
            <TableRow
              key={affiliate.id}
              onClick={() => goToAffiliateDetailPage(affiliate.id)}
              className={classes.tableRow}
            >
              {affiliateTableColumns.map((column) => (
                <TableCell key={`${column}${affiliate.id}`}>
                  {affiliate[column] || NOT_APPLICABLE_ABBREVIATION}
                </TableCell>
              ))}
              <TableCell>
                {formatMonthDayYearDate(affiliate.createdAt)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
