import { affiliateUrls } from "@common/api/affiliateUrls";
import { ajax } from "@common/services/ajax";
import { AffiliateDataType, AffiliateType } from "@common/types/affiliateType";
import { RafRewardType } from "@common/types/rafTypes";

export const affiliateApi = {
  create: (affiliateData: AffiliateDataType): Promise<AffiliateType> => {
    return ajax.post<AffiliateType, AffiliateDataType>(
      affiliateUrls.create(),
      affiliateData
    );
  },
  retrieve: (affiliateId: string): Promise<AffiliateType> => {
    return ajax.get(affiliateUrls.retrieve(affiliateId));
  },
  referrals: (affiliateId: string): Promise<RafRewardType[]> => {
    return ajax.get(affiliateUrls.referrals(affiliateId));
  },
  update: (
    affiliateId: string,
    affiliateData: AffiliateDataType
  ): Promise<AffiliateType> => {
    return ajax.patch<AffiliateType, AffiliateDataType>(
      affiliateUrls.retrieve(affiliateId),
      affiliateData
    );
  },
};
