import { rewardsApi } from "@common/api/rewardsApi";
import { RhApiError } from "@common/types/errorTypes";
import { separateNumber } from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { Box } from "@material-ui/core";
import { AccountRewardsTable } from "@ops/components/AccountRewards/AccountRewardsTable";
import { useGetCustomerRewardHistory } from "@ops/hooks/useGetCustomerRewardHistory";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import React, { ChangeEvent, FC, useState } from "react";
import { useSelector } from "react-redux";

export const AccountRewards: FC = () => {
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [pointValue, setPointValue] = useState<number>(0);
  const [verificationModalOpen, setVerificationModalOpen] = useState<boolean>(
    false
  );
  const [submittingPoints, setSubmittingPoints] = useState<boolean>(false);
  const { id: customerId } = useSelector(selectCustomerState);
  const {
    data: rewards,
    requestMonitor: rewardsFetchRequestMonitor,
    triggerRefetch: refetchRewards,
  } = useGetCustomerRewardHistory(customerId);
  const flash = useRhFlash();

  const handleInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const sanitizedValue = parseInt(value.replace(/\D/g, ""), 10);

    setSubmitDisabled(!(sanitizedValue > 0));
    setPointValue(sanitizedValue ?? 0);
  };

  const openVerificationModal = () => {
    setVerificationModalOpen(true);
  };
  const closeVerificationModal = () => {
    setVerificationModalOpen(false);
  };

  const submitAddPoints = () => {
    setSubmittingPoints(true);

    rewardsApi.customers
      .addPoints(customerId, pointValue)
      .then(({ success }) => {
        if (success) {
          flash.success(
            `Successfully rewarded ${separateNumber(pointValue)} points`
          );
          refetchRewards();
        } else {
          flash.error(
            "Something went wrong. Please check that your point value is more than 0."
          );
        }
      })
      .catch((_error: RhApiError) => {
        flash.error(
          "There was an issue adding the points to the customer. Please try again."
        );
      })
      .finally(() => {
        setSubmittingPoints(false);
        setPointValue(0);
        closeVerificationModal();
      });
  };

  return (
    <>
      <Box mb={3} id="rewards">
        <Box mb={1}>
          <RhTypography variant="h2">Rewards</RhTypography>
        </Box>
        <Box mb={1}>
          {rewardsFetchRequestMonitor.isWaiting ? (
            <RhCircularProgress />
          ) : (
            <AccountRewardsTable rewards={rewards} />
          )}
        </Box>
        <Box mb={1}>
          <RhFlexBox flexDirection="row" alignItems="center">
            <Box width="50%" mr={2}>
              <RhTextInput
                name="points"
                placeholder="Points"
                onChange={handleInputChange}
                value={pointValue || ""}
              >
                <RhTypography>Add Points to Account</RhTypography>
              </RhTextInput>
            </Box>
            <Box ml={2}>
              <RhButton
                variant="outlined"
                disabled={submitDisabled}
                onClick={openVerificationModal}
              >
                Add Rewards Points
              </RhButton>
            </Box>
          </RhFlexBox>
        </Box>
      </Box>
      <RhDialog
        open={verificationModalOpen}
        onClose={closeVerificationModal}
        size="small"
      >
        {submittingPoints ? (
          <RhCircularProgress />
        ) : (
          <>
            <RhDialogTitle>Please Confirm Action</RhDialogTitle>
            <RhDialogContentWithButtons
              primaryButton={{ label: "Confirm", onClick: submitAddPoints }}
              secondaryButton={{
                label: "Cancel",
                onClick: closeVerificationModal,
              }}
            >
              <RhTypography>
                {`You are about to reward ${separateNumber(
                  pointValue
                )} points worth $${rewardPointsToDollars(
                  pointValue
                )} to this customer. Do you want to proceed?`}
              </RhTypography>
            </RhDialogContentWithButtons>
          </>
        )}
      </RhDialog>
    </>
  );
};
