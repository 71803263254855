import { MeterType } from "@common/types/meterTypes";

export class Meter {
  public addressLine1!: string;
  public city!: string;
  public cityName!: string;
  public createdAt!: string;
  public deliveryPointBarcode!: string;
  public esiId!: string;
  public plus4Code!: string;
  public premiseType!: string;
  public primaryNumber!: string;
  public rawAddress!: string;
  public rawAddressOverflow!: string;
  public rawCity!: string;
  public rawState!: string;
  public rawZipCode!: string;
  public secondaryDesignator!: string;
  public secondaryNumber!: string;
  public state!: string;
  public streetName!: string;
  public streetSuffix!: string;
  public isSmt!: boolean;
  public unitNumber!: string;
  public updatedAt!: string;
  public zipCode!: string;

  public constructor(meter: MeterType) {
    Object.assign(this, meter);
  }
}
