import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core";

export const useCustomerSearchFormStyles = makeStyles<
  typeof rhythmTheme,
  "root" | "searchForm" | "searchButton"
>((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(5),
      alignSelf: "center",
      width: "80%",
      maxWidth: "1000px",
    },
    searchButton: {
      minWidth: 150,
      marginLeft: theme.spacing(2),
    },
    searchForm: {
      display: "flex",
      marginBottom: theme.spacing(1),
    },
  })
);
