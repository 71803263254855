import { Customer } from "@common/models/Customer.model";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { UpdateMailingAddressFields } from "@ops/components/UpdateMailingAddress/UpdateMailingAddressFields";
import { customerMailingAddressFormValidator } from "@ops/forms/validators";
import { CustomerMailingAddressFormValues } from "@ops/types/customerContactTypes";
import React, { FC } from "react";
import { Form } from "react-final-form";

export interface SimpleDialogProps {
  customer: Customer;
  open: boolean;
  setClose: () => void;
  onSubmit: (
    newMailingAddress: CustomerMailingAddressFormValues
  ) => Promise<void>;
}

export const UpdateMailingAddressDialog: FC<SimpleDialogProps> = ({
  customer,
  open,
  setClose,
  onSubmit,
}) => {
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={setClose}
      maxWidth="md"
    >
      <DialogTitle>Update Mailing Address</DialogTitle>
      <DialogContent>
        <Form<CustomerMailingAddressFormValues>
          initialValues={{
            mailingAddressSameAsServiceAddress:
              customer.mailingAddressSameAsServiceAddress,
            ...customer.mailingAddress,
          }}
          onSubmit={onSubmit}
          validate={customerMailingAddressFormValidator}
          validateOnBlur
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <UpdateMailingAddressFields />
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};
