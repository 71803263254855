import { rhMakeStyles as makeStyles } from "@design-system/theme/helpers/rhMakeStyles";
import { rhythmTheme } from "@design-system/theme/rhythm.theme";
import { createStyles } from "@material-ui/core/styles";

type RafReferralUrlStylesKeys = "input" | "copyIcon" | "button" | "root";

export const useRafReferralUrlStyles = makeStyles<
  typeof rhythmTheme,
  RafReferralUrlStylesKeys
>((theme) => {
  return createStyles({
    root: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    input: {
      color: theme.palette.primary.main,
      width: "100%",
      marginRight: theme.spacing(2),
    },
    copyIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      width: 200,
      marginLeft: "auto",
    },
  });
});
