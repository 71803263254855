import { NOT_APPLICABLE_ABBREVIATION } from "@common/constants/other.constant";
import { Customer } from "@common/models/Customer.model";
import { CustomerType } from "@common/types/customerTypes";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { OPS_CUSTOMER_DETAIL_BUTTON_WIDTH } from "@design-system/theme/style.constant";
import { Box } from "@material-ui/core";
import { UpdateEsiId } from "@ops/components/UpdateEsiId/UpdateEsiId";
import { selectCustomerState } from "@ops/selectors/customer.selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";

export const AccountService: FC = () => {
  const customerState = useSelector(selectCustomerState);
  const customer = new Customer(customerState as CustomerType);

  return (
    <Box marginBottom={2} id="service">
      <Box>
        <RhTypography variant="h2">Service</RhTypography>
      </Box>
      <RhFlexBox justifyContent="space-between" alignItems="center">
        <Box marginBottom={2}>
          <RhTypography variant="body1">
            ESI-ID: {customer.meter.esiId || NOT_APPLICABLE_ABBREVIATION}
          </RhTypography>
          {customer.lifeSupport && (
            <RhTypography color="error">CUSTOMER ON LIFE SUPPORT</RhTypography>
          )}
        </Box>
        <Box>
          <RhTypography variant="h3">Service Address</RhTypography>
          <RhTypography variant="body1">
            {customer.activePremiseAddress.line1}
          </RhTypography>
          <RhTypography variant="body1">
            {customer.activePremiseAddress.line2}
          </RhTypography>
        </Box>
      </RhFlexBox>
      <Box width={OPS_CUSTOMER_DETAIL_BUTTON_WIDTH} padding={2}>
        <UpdateEsiId customer={customer} />
      </Box>
    </Box>
  );
};
