export const affiliatesPath = () => {
  return "/affiliates";
};

export const newAffiliatePath = () => {
  return "/affiliates/new";
};

export const affiliateDetailPath = (affiliateId: string) => {
  return `/affiliates/${affiliateId}`;
};

export const affiliateEditPath = (affiliateId: string) => {
  return `${affiliateDetailPath(affiliateId)}/edit`;
};

export const customersPath = () => {
  return "/customers";
};

export const customerDetailPath = (customerId: string) => {
  return `/customers/${customerId}`;
};

export const customerInvoicePath = (customerId: string) => {
  return `/customers/${customerId}/invoices/`;
};

export const customerSearchPath = () => {
  return "/customers/search";
};

export const homePath = (): string => {
  return "/";
};

export const signInPath = (): string => {
  return "/sign-in";
};

export const logoutPath = (): string => {
  return "/logout";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};
