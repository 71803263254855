import { IdType, ZuoraIdType } from "@common/types/apiTypes";

export const billingFetchRSASignature = (): string =>
  "/api/billing/rsa_signature";
export const paymentMethodsRetrieve = (id: ZuoraIdType): string =>
  `/api/billing/payment_methods/${id}/`;
export const paymentMethodsDelete = (id: ZuoraIdType): string =>
  `/api/billing/payment_methods/${id}/delete/`;
export const paymentMethodsUpdate = (id: ZuoraIdType): string =>
  paymentMethodsRetrieve(id);
export const paymentMethodsRetrieveDefault = (id: ZuoraIdType): string =>
  `${paymentMethodsRetrieve(id)}default/`;
export const paymentMethodsList = (): string => "/api/billing/payment_methods/";
export const customerInvoiceHistory = (customerId: IdType): string =>
  `/api/billing/generated_invoice/${customerId}/list_invoice_history/`;
export const downloadInvoiceJson = (id: IdType): string =>
  `/api/billing/generated_invoice/${id}/download/`;
export const uploadInvoiceJson = (id: IdType): string =>
  `/api/billing/generated_invoice/${id}/upload/`;
