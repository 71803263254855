import { useRhAccordionStyles } from "@design-system/components/RhAccordion/RhAccordion.style";
import { Accordion, AccordionProps } from "@material-ui/core";
import React, { FC } from "react";

export const RhAccordion: FC<AccordionProps> = ({ children, ...rest }) => {
  const classes = useRhAccordionStyles();

  return (
    <Accordion
      square
      elevation={0}
      variant="elevation"
      classes={classes}
      {...rest}
    >
      {children}
    </Accordion>
  );
};
