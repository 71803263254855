import { updatePremise } from "@common/api/ediApi";
import { Customer } from "@common/models/Customer.model";
import { PremiseUpdateResponseType } from "@common/types/apiResponseTypes";
import { CustomerType } from "@common/types/customerTypes";
import { RhDialog } from "@design-system/components/RhDialog/RhDialog";
import { RhDialogContentWithButtons } from "@design-system/components/RhDialogContentWithButtons/RhDialogContentWithButtons";
import { RhDialogTitle } from "@design-system/components/RhDialogTitle/RhDialogTitle";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { UpdateEsiIdDialog } from "@ops/components/UpdateEsiId/UpdateEsiIdDialog";
import { customerUpdated } from "@ops/slices/customerSlice";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

export interface PremiseFormValues {
  esiId: string;
}

interface UpdateEsiIdProps {
  customer: Customer;
}

export const UpdateEsiId: FC<UpdateEsiIdProps> = ({ customer }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [esiId, setEsiId] = useState<string>("");
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const openConfirmation = (premiseValues: PremiseFormValues) => {
    setEsiId(premiseValues.esiId);
    setIsConfirmationOpen(true);
  };
  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    const premiseValues: PremiseFormValues = { esiId };

    updatePremise(customer.activePremise.id, { meterId: premiseValues.esiId })
      .then((updatedPremise: PremiseUpdateResponseType) => {
        dispatch(
          customerUpdated(({
            activePremise: updatedPremise,
            meter: updatedPremise?.meter,
          } as unknown) as CustomerType)
        );
        flash.success("ESI ID successfully updated for customer.");
      })
      .catch(() => {
        flash.error("Unable to update ESI ID. Please try again.");
      })
      .finally(() => {
        closeConfirmation();
        setDialogOpen(false);
      });
  };

  const updateEsiIdAvailable =
    customer.enrollmentStatus === "CANCELED" || !customer.activePremise.meter;

  return (
    <>
      <RhOutlineButton
        onClick={(_event) => setDialogOpen(true)}
        fullWidth
        disabled={!updateEsiIdAvailable}
      >
        Update ESI ID
      </RhOutlineButton>
      <UpdateEsiIdDialog
        customer={customer}
        open={dialogOpen}
        onSubmit={openConfirmation}
      />
      <RhDialog open={isConfirmationOpen}>
        <RhDialogTitle>Confirm ESI ID Change</RhDialogTitle>
        <RhDialogContentWithButtons
          primaryButton={{
            label: "Change ESI ID",
            onClick: handleSubmit,
          }}
          secondaryButton={{
            label: "Cancel",
            onClick: closeConfirmation,
          }}
        >
          <RhTypography variant="subtitle2" color="textSecondary">
            {`This will change the ESI ID to ${esiId}. Would you like to continue?`}
          </RhTypography>
        </RhDialogContentWithButtons>
      </RhDialog>
    </>
  );
};
